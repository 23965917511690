
import { IToursHeaders, ITours, ITourSettings, } from './interface/interfaces';
import { ClientApi } from '@vva/front';
import { createTourBody } from './api-body-generator';
import { IVTConfig } from '../components/main/settings/settings';

const clientApi = new ClientApi();

// conditional window ENV for testing with jest
clientApi.setHeaders({
	'X-Api-Key': (window.ENV && window.ENV.REACT_APP_API_KEY ? window.ENV.REACT_APP_API_KEY : null) || process.env.REACT_APP_API_KEY,
});

const getTours = async (page: number, keyword: string, order: string): Promise<IToursHeaders> => {
	const response = await clientApi.getDataWithHeaders(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/tours?limit=9&page=${page + 1}&keyword=${keyword}&orderBy=${order}`,
	);
	return { data: response.data.data, headers: response.headers };
};

const getUsersTours = async (page: number, keyword: string, order: string): Promise<IToursHeaders> => {
	const params = new URLSearchParams(window.location.search);
	const imageEnv = params.get('imageEnv');

	const response = await clientApi.getDataWithHeaders(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/tours/users?limit=9&page=${page + 1
		}&keyword=${keyword}&orderBy=${order}&imageEnv=${imageEnv}`,
	);
	return { data: response.data.data, headers: response.headers };
};

const getUsersDeletedTours = async (page: number, keyword: string, order: string): Promise<IToursHeaders> => {
	const params = new URLSearchParams(window.location.search);
	const imageEnv = params.get('imageEnv');

	const response = await clientApi.getDataWithHeaders(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/tours/usersDeletedTours?limit=9&page=${page + 1
		}&keyword=${keyword}&orderBy=${order}&imageEnv=${imageEnv}`,
	);
	return { data: response.data.data, headers: response.headers };
};

const getUsersArchivedTours = async (page: number, keyword: string, order: string): Promise<IToursHeaders> => {
	const params = new URLSearchParams(window.location.search);
	const imageEnv = params.get('imageEnv');

	const response = await clientApi.getDataWithHeaders(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/tours/usersArchivedTours?limit=9&page=${page + 1
		}&keyword=${keyword}&orderBy=${order}&imageEnv=${imageEnv}`,
	);
	return { data: response.data.data, headers: response.headers };
};

const getTour = async (tourId: string): Promise<ITours> => {
	const params = new URLSearchParams(window.location.search);
	const imageEnv = params.get('imageEnv');

	const response = await clientApi.getData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/tours/${tourId}?imageEnv=${imageEnv}`,
	);
	return response.data;
};

const createTour = async (tour: any) => {
	const newTour = createTourBody(tour);
	const response = await clientApi.postData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/tours`,
		newTour,
	);
	return response.data;
};

const updateTour = async (tourBody: any) => {
	const updatedTour = createTourBody(tourBody);
	const tourId: string = tourBody.id;
	const response = await clientApi.putData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/tours/${tourId}`,
		updatedTour,
	);
	return response.data;
};

const updateTourSettings = async (tourId: string, body: ITourSettings) => {
	const response = await clientApi.putData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/tours/${tourId}/config`, {
		id: tourId,
		...body,
	}
	);
	return response.data;
};

const getArchivedTours = async (): Promise<ITours[]> => {
	const params = new URLSearchParams(window.location.search);
	const imageEnv = params.get('imageEnv');

	const response = await clientApi.getData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/tours/archived?imageEnv=${imageEnv}`,
	);
	return response.data;
};

const getDeletedTours = async (): Promise<ITours[]> => {
	const params = new URLSearchParams(window.location.search);
	const imageEnv = params.get('imageEnv');

	const response = await clientApi.getData(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/tours/deleted?imageEnv=${imageEnv}`);
	return response.data;
};

const deleteTour = async (id: string) => {
	const deleteResponse = await clientApi.deleteData(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/tours/${id}`);
	return deleteResponse;
};

const deleteArchivedTour = async (id: string) => {
	const deleteResponse = await clientApi.deleteData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/tours/${id}/archivedTour`,
	);
	return deleteResponse;
};

const archiveTour = async (id: string, status: boolean) => {
	const deleteResponse = await clientApi.putData(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/tours/${id}/archive`, {
		status: status,
	});
	return deleteResponse;
};

const getRestoreTour = async (tourId: string): Promise<ITours> => {
	const response = await clientApi.putData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/tours/${tourId}/restore`,
		{},
	);
	return response.data;
};

const duplicateTour = async (tourId: string): Promise<ITours> => {
	const response = await clientApi.postData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/tours/duplicate`,
		{
			id: tourId,
		},
	);
	return response.data;
};

const protectTour = async (tourId: string, password: string): Promise<any> => {
	const response = await clientApi.putData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/tours/${tourId}/protect`,
		{
			password,
		},
	);
	return response.data;
};

const unProtectTour = async (tourId: string): Promise<any> => {
	const response = await clientApi.putData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/tours/${tourId}/unProtect`,
		{},
	);
	return response.data;
};

const shareTour = async (id: string, email: string | string[] | undefined) => {
	const response = await clientApi.postData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/tours/${id}/email`,
		{
			email,
		},
	);
	return response.data;
};

const setDefaultTripodTour = async (tourId: string, logoId: string) => {
	const response = await clientApi.postData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/tours/${tourId}/tripodCap`,
		{
			logoId,
		},
	);
	return response.data;
};

const getDefaultTripodTour = async (tourId: string) => {
	const response = await clientApi.getData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/tours/${tourId}/tripodCap`,
	);
	return response.data;
};

const updateTourLeadsConfig = async (body: any, tourId: string) => {
	const response = await clientApi.putData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/tours/${tourId}/config`,
		body,
	);
	return response;
};

const updateTourGeneralConfig = async (body: IVTConfig, tourId: string) => {
	const response = await clientApi.putData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/tours/${tourId}/config`,
		body,
	);
	return response;
};

const getTourConfig = async (tourId: string) => {
	const updateConfig = await clientApi.getData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/tours/${tourId}/config`,
	);
	return updateConfig.data;
};

const moveTourDifferentAccountAdmin = async (tourId: string, newUserEmail: string) => {
	const response = await clientApi.putData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/tours/moveTourUser`,
		{
			tourId,
			newUserEmail,
		},
	);
	return response;
};

export {
	getTours,
	getTour,
	createTour,
	updateTour,
	updateTourSettings,
	getTourConfig,
	getArchivedTours,
	deleteTour,
	deleteArchivedTour,
	getDeletedTours,
	archiveTour,
	getRestoreTour,
	duplicateTour,
	protectTour,
	unProtectTour,
	shareTour,
	setDefaultTripodTour,
	getDefaultTripodTour,
	updateTourLeadsConfig,
	updateTourGeneralConfig,
	getUsersTours,
	getUsersDeletedTours,
	getUsersArchivedTours,
	moveTourDifferentAccountAdmin,
};
