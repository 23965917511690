import { Trans } from '@lingui/react';
import React, { useState, useEffect } from 'react';
import { EnumCategory, EnumEvent } from '../../../google-analytics-track-list';
import { ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { ISidebar } from '../../editing-viewer/viewer-canvas';
import ModalConfirmApplyAll from './modal-confirm-apply-all';
import { IBranding, IFiles } from './settings';
import './settings.scss';
import { useConfig } from '../../utils/user-config-context/user-config-context';
import { linguiTranslateToString } from '../../utils/lingui-utils/utils-lingui';
import { createSubscriptionPortal } from '../../../api-helper/api-payment';
import { handleError } from '../../../api-helper/api-error-handling';
import { useAuth } from '../../../auth-routes/auth-context';
import Notification, { EnumNotificationSize, EnumNotificationType } from '../../utils/notifications/notification';

interface IProps {
  sidebar: ISidebar;
  branding: IBranding;
  files: IFiles[];
  setBranding: (enable: IBranding) => void;
  fileDropRef: any;
  onFileDrop: (event: any) => void;
  deleteSelectedLogo: (imageIndex: number) => void;
  selectDefaultLogo: (selectedIndex: number) => void;
  toggleModal: (state: boolean) => void;
  itemModalOpen: boolean;
  onSaveBranding: (global: boolean) => void;
}

const Branding: React.FC<IProps> = ({
  sidebar,
  branding,
  files,
  setBranding,
  fileDropRef,
  onFileDrop,
  deleteSelectedLogo,
  selectDefaultLogo,
  toggleModal,
  itemModalOpen,
  onSaveBranding,
}) => {
  const [proAccount, setProAccount] = useState(false);
  const config = useConfig();
  const [currency, setCurrency] = useState(linguiTranslateToString('gbp'));
  const { handleLogoutState } = useAuth();
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [typeNotification, setTypeNotification] = useState<EnumNotificationType>();
  const [notificationMessage, setNotificationMessage] = useState<string>('');

  const toggleNotification = (type: EnumNotificationType, message?: string) => {
    setTypeNotification(type);
    setNotificationMessage(message || linguiTranslateToString('There was an error please try again'));
    setShowNotification(true);
  };

  useEffect(() => {
    if (config) {
      const currency = config.countryInfo;
      if (currency) {
        setCurrency(currency.currency.toLowerCase());
      }
    }
  }, [config]);

  useEffect(() => {
    if (sidebar.tripodBranding) {
      setProAccount(sidebar.tripodBranding);
    }
  }, [sidebar]);

  const upgradeToProPlan = async () => {
    try {
      const createPaymentSession = await createSubscriptionPortal(currency);
      if (createPaymentSession?.redirectUrl) {
        // redirect to payment checkout
        window.location.href = createPaymentSession.redirectUrl;
      }
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      toggleNotification(EnumNotificationType.Error, linguiTranslateToString('There was an issue creating payment session'));
    }
  };

  return (
    <>
      {showNotification && (
        <Notification
          showNotification={showNotification}
          size={EnumNotificationSize.Large}
          type={typeNotification || EnumNotificationType.Info}
          message={notificationMessage}
          setShowNotification={setShowNotification}
          fullScreen={true}
        />
      )}
      <div className='settings-information-container'>
        {itemModalOpen && <ModalConfirmApplyAll setShowDeleteModal={toggleModal} applyAll={onSaveBranding} />}
        <div className='settings-information-layout'>
          <div className='panelhook-container'>
            <div>
              <div>
                <div className='settings-config-block-tripod'>
                  <div className='settings-config-option'>
                    <div style={{ color: !proAccount ? '#818AA6' : '', cursor: 'default' }}>
                      <Trans id='Branded tours' render={({ translation }) => <div className='field-text'>{translation}</div>} />
                      <Trans
                        id='Add your logo on all your 360° virtual tour'
                        render={({ translation }) => (
                          <div style={{ color: !proAccount ? '#818AA6' : '' }} className='field-subtext'>
                            {translation}
                          </div>
                        )}
                      />
                    </div>
                    <div className='field-input-container'>
                      {proAccount ? (
                        <label className='switch-settings'>
                          <input
                            id='enableTripod'
                            type='checkbox'
                            checked={branding.forceBrand || false}
                            disabled={!proAccount}
                            onClick={() => {
                              if (branding.forceBrand) {
                                //Report Custom Category and Event
                                ReportCustomEvent(EnumCategory.MainMenu, EnumEvent.DisableTripodCapLogo);
                              } else {
                                //Report Custom Category and Event
                                ReportCustomEvent(EnumCategory.MainMenu, EnumEvent.EnableTripodCapLogo);
                              }
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setBranding({ ...branding, forceBrand: e.target.checked === true ? true : false })
                            }
                          />
                          <span id='slider-branding' className='slider-settings round-settings'>
                            <span className={`slider-text-${branding.forceBrand ? 'on' : 'off'}`}>
                              {branding.forceBrand ? 'ON' : 'OFF'}
                            </span>
                          </span>
                        </label>
                      ) : (
                        <label className='switch-settings'>
                          <input id='enableTripod' type='checkbox' checked={branding.forceBrand} disabled={!proAccount} />
                          <span id='slider-auto-start-tour' className='slider-settings-disable round-settings'></span>
                        </label>
                      )}
                    </div>
                  </div>
                </div>
                <div className='container-inputs-branding'>
                  <div className='input-box'>
                    <Trans id='Company name *' render={({ translation }) => <div className='branding-input-labels'>{translation}</div>} />
                    <input
                      className={`general-field-input-text-branding ${branding.brandName !== '' ? 'valid-input' : ''} ${
                        !proAccount && 'general-field-input-text-disabled'
                      }`}
                      type='text'
                      id='companyName'
                      name='companyName'
                      value={branding.brandName || ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBranding({ ...branding, brandName: e.target.value })}
                      disabled={!proAccount}
                    />
                  </div>
                  <div className='input-box'>
                    <Trans id='Address *' render={({ translation }) => <div className='branding-input-labels'>{translation}</div>} />
                    <input
                      className={`general-field-input-text-branding ${branding.brandAddress !== '' ? 'valid-input' : ''} ${
                        !proAccount && 'general-field-input-text-disabled'
                      }`}
                      type='text'
                      id='brandAddress'
                      name='brandAddress'
                      value={branding.brandAddress || ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBranding({ ...branding, brandAddress: e.target.value })}
                      disabled={!proAccount}
                    />
                  </div>
                  <div className='input-box'>
                    <Trans id='Email *' render={({ translation }) => <div className='branding-input-labels'>{translation}</div>} />
                    <input
                      className={`general-field-input-text-branding ${branding.brandEmail !== '' ? 'valid-input' : ''} ${
                        !proAccount && 'general-field-input-text-disabled'
                      }`}
                      type='text'
                      id='brandEmail'
                      name='brandEmail'
                      value={branding.brandEmail || ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBranding({ ...branding, brandEmail: e.target.value })}
                      disabled={!proAccount}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='your-branding-options'>
              <div className='bottom-set-settings'>
                <div className='settings-drag-drop-field-container'>
                  <div className='drag-drop-text-container'>
                    <Trans
                      id='Upload your logo'
                      render={({ translation }) => (
                        <label style={{ color: !proAccount ? '#818AA6' : '' }} className='field-text'>
                          <b>{translation}</b>
                        </label>
                      )}
                    />
                    <Trans
                      id='Customise the virtual tours with your logo. Logo must be PNG or JPEG '
                      render={({ translation }) => (
                        <label style={{ color: !proAccount ? '#818AA6' : '' }} className='field-subtext'>
                          {translation}
                        </label>
                      )}
                    />
                  </div>
                  <div style={{ color: !proAccount ? '#818AA6' : '' }} className='drag-drop-input-container-branding'>
                    <div className='drag-drop-zone'>
                      <div className='drag-drop-container-circle' style={{ backgroundColor: !proAccount ? '#F2F3F5' : '' }}>
                        <div className='drag-drop-text-container-circle'>
                          <Trans
                            id='Drop logo here'
                            render={({ translation }) => (
                              <p style={{ color: proAccount ? '#0e0333' : '#C7CCDA' }} className='drag-drop-text-title'>
                                {translation}
                              </p>
                            )}
                          />
                          <Trans
                            id='or'
                            render={({ translation }) => (
                              <div style={{ color: proAccount ? '#0e0333' : '#C7CCDA' }} className='media-drop-or-settings'>
                                {translation}
                              </div>
                            )}
                          />
                          <Trans
                            id='Upload logo'
                            render={({ translation }) => (
                              <p className={proAccount ? 'drag-drop-text-title-btn' : 'drag-drop-text-title-btn-disabled'}>{translation}</p>
                            )}
                          />
                        </div>
                        <input
                          id='input-file'
                          type='file'
                          name='logo-drop-zone'
                          className='branding-input-settings'
                          multiple
                          style={{ cursor: !proAccount ? 'default' : '' }}
                          onDragOver={e => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                          ref={fileDropRef}
                          onDrop={(e: React.DragEvent<HTMLDivElement>) => onFileDrop(e)}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onFileDrop(e)}
                          disabled={!proAccount}
                          onClick={() => {
                            //Report Custom Category and Event
                            ReportCustomEvent(EnumCategory.MainMenu, EnumEvent.UploadLogoFile);
                          }}
                        />
                      </div>
                    </div>
                    <div className='drag-drop-display'>
                      {proAccount &&
                        files.map((file: IFiles, index: number) => (
                          <div key={index} className='logo-image-container'>
                            <div className='dd-cross-container-logo' onClick={() => deleteSelectedLogo(index)}></div>
                            <img src={file.data} alt={file.name} className='dd-image-logo' />
                            {file.defaultLogo === true ? (
                              <Trans
                                id='Default logo'
                                render={({ translation }) => (
                                  <p id='default-logo' className='dd-text-logo underlined default-logo'>
                                    {translation}
                                  </p>
                                )}
                              />
                            ) : (
                              <Trans
                                id='Set as a default logo'
                                render={({ translation }) => (
                                  <p
                                    id='set-default-logo'
                                    className='dd-text-logo underlined not-default-logo'
                                    onClick={() => selectDefaultLogo(index)}
                                  >
                                    {translation}
                                  </p>
                                )}
                              />
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {proAccount ? (
              <div className='save-information-button-container'>
                <div>
                  <button
                    id='apply-all-branding'
                    className={`save-information-button ${
                      branding.brandName !== '' && branding?.brandAddress !== '' && branding?.brandEmail !== ''
                        ? 'button-white button-hover'
                        : 'button-inactive'
                    }`}
                    onClick={(e: React.MouseEvent) => {
                      e.preventDefault();
                      if (branding.brandName !== '' && branding?.brandAddress !== '' && branding?.brandEmail !== '') {
                        toggleModal(true);
                      }
                    }}
                  >
                    <Trans id='Apply to all' />
                  </button>
                </div>
                <div>
                  <button
                    id='save-btn-branding'
                    className={`save-information-button ${
                      branding.brandName !== '' && branding?.brandAddress !== '' && branding?.brandEmail !== ''
                        ? 'button-active button-hover'
                        : 'button-inactive'
                    }`}
                    onClick={() => {
                      ReportCustomEvent(EnumCategory.MainMenu, EnumEvent.YourBrandingSettings);
                      const global = false;
                      if (branding.brandName !== '' && branding?.brandAddress !== '' && branding?.brandEmail !== '') {
                        onSaveBranding(global);
                      }
                    }}
                  >
                    <Trans id='Save changes' />
                  </button>
                </div>
              </div>
            ) : (
              <div className='save-information-button-container'>
                <button
                  onClick={() => {
                    ReportCustomEvent(EnumCategory.MainMenu, EnumEvent.UpdateToProAccount);
                    upgradeToProPlan();
                  }}
                  id='save-btn-your-branding'
                  className='save-information-button button-active'
                >
                  <Trans id='Upgrade account to PRO to unlock this feature' />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Branding;
