import React from 'react';
import EmptyTours from './empty-tours';
import TourRows from '../../utils/tour-views/tour-rows';
import LoadingSpinner from '../../utils/loading-spinner/loading-spinner';
import Pagination from '../../utils/pagination/pagination';
import { EnumTabsState, IPagination } from './dashboard';
import { ITours } from '../../../api-helper/interface/interfaces';
import { EnumDollhouseStatus, IModalContent } from '../../utils/tour-views/interface';
import { handleError } from '../../../api-helper/api-error-handling';
import { getRestoreTour } from '../../../api-helper/api-tours';
import { linguiTranslateToString } from '../../utils/lingui-utils/utils-lingui';
import { EnumNotificationType } from '../../utils/notifications/notification';
import { useAuth } from '../../../auth-routes/auth-context';

interface IProps {
    keyword: string;
    orderOptionName: string;
    orderOption: string;
    loaded: boolean;
    error: boolean;
    tours?: ITours[];
    toursRef: React.MutableRefObject<HTMLDivElement[]>;
    archiveTours: (id: string, archive: boolean) => void;
    duplicateATour: (tourId: string) => void;
    showModal: (modalContent: IModalContent) => void;
    handlePaginationSearch: (selected: IPagination) => void;
    pageCount: number;
    page: number;
    itHasTours: boolean;
    isTooltipActive?: boolean;
    activeTooltipHandler?: () => void;
    connectToUserGoToStudio?: (userId: string, tourId: string) => void;
    downloadAllPanoramas: (tourId: string, tourName: string) => void;
    showDollhouseTab?: boolean;
    showOrderDollhouse?: boolean;
    master?: boolean;
    changeDollhouseStatus?: (tourId: string, status: EnumDollhouseStatus) => void;
    liveTourTab?: EnumTabsState;
    changePaginationPage: (page: IPagination) => void;
    apiNoPayment?: boolean;
    type: string;
    toggleNotification: (type: EnumNotificationType, message?: string) => void;
    scrollToTop: () => void;
    getDeletedTours?: () => void;
}

const UserArchivedTours: React.FC<IProps> = ({
    keyword,
    orderOptionName,
    orderOption,
    loaded,
    error,
    tours,
    toursRef,
    archiveTours,
    duplicateATour,
    showModal,
    pageCount,
    page,
    itHasTours,
    downloadAllPanoramas,
    showDollhouseTab,
    showOrderDollhouse,
    liveTourTab,
    changePaginationPage,
    apiNoPayment,
    type,
    toggleNotification,
    scrollToTop,
    getDeletedTours,
}) => {
    const { handleLogoutState } = useAuth();

    const restoreTours = async (id: string) => {
        try {
            await getRestoreTour(id);
            if (getDeletedTours) {
                await getDeletedTours();
            }
            const message = linguiTranslateToString('Tour successfully restored');
            toggleNotification(EnumNotificationType.Success, message);
        } catch (error) {
            const message = linguiTranslateToString('There was an error restoring tour');
            toggleNotification(EnumNotificationType.Error, message);
            const err = error as Error;
            handleError(err, handleLogoutState);
        }
    };

    if (!loaded && !error) {
        return <LoadingSpinner loaded={loaded} />;
    } else if (loaded && tours && tours.length < 1) {
        return <EmptyTours itHasTours={itHasTours} liveTourTab={liveTourTab} />;
    } else {
        return (
            <>
                <TourRows
                    page={page}
                    keyword={keyword}
                    tours={tours}
                    toursRef={toursRef}
                    showModal={showModal}
                    archiveTours={archiveTours}
                    duplicateATour={duplicateATour}
                    orderOptionName={orderOptionName}
                    orderOption={orderOption}
                    downloadAllPanoramas={downloadAllPanoramas}
                    showDollhouseTab={showDollhouseTab}
                    showOrderDollhouse={showOrderDollhouse}
                    apiNoPayment={apiNoPayment || false}
                    restoreTours={restoreTours}
                    type={type}
                />
                {tours && (
                    <div className='pagination-container'>
                        <Pagination page={page} pageCount={pageCount} handlePaginationSearch={changePaginationPage} />
                    </div>
                )}
            </>
        );
    }
};

export default UserArchivedTours;
