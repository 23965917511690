import React, { useState, useEffect } from 'react';
import { Trans } from '@lingui/react';
import { ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../../google-analytics-track-list';
import { IPanorama, ITourSettings } from '../../../api-helper/interface/interfaces';
import { EnumNotificationType } from '../../utils/notifications/notification';
import { INewError } from '@vva/front/api-client/interfaces';
import { handleError } from '../../../api-helper/api-error-handling';
import { useAuth } from '../../../auth-routes/auth-context';
import { updateTourSettings } from '../../../api-helper/api-tours';
import { ISidebar } from '../viewer-canvas';
import { createSubscriptionPortal } from '../../../api-helper/api-payment';
import { linguiTranslateToString } from '../../utils/lingui-utils/utils-lingui';
import { useConfig } from '../../utils/user-config-context/user-config-context';
import ModalOptimiseTripod from '../../main/settings/modal-optimise-tripod';
import useInterval from '../../utils/interval-hook/use-interval';

interface IProps {
  sidebar: ISidebar;
  tour: any;
  aiSettings: ITourSettings;
  changeLeftSidebar: (option: string) => void;
  toggleNotification: (type: EnumNotificationType, message?: string, duration?: number) => void;
  panoramaData: any;
  setLoaded: (state: boolean) => void;
  currentPanorama: IPanorama;
  updatePanoramaList: () => void;
}

const EditAiFeatures: React.FC<IProps> = ({
  sidebar,
  tour,
  aiSettings,
  changeLeftSidebar,
  toggleNotification,
  panoramaData,
  setLoaded,
  currentPanorama,
  updatePanoramaList,
}) => {
  const { handleLogoutState } = useAuth();
  const [newAiSettings, setNewAiSettings] = useState<ITourSettings>();
  const [proAccount, setProAccount] = useState<boolean>(false);
  const config = useConfig();
  const [currency, setCurrency] = useState(linguiTranslateToString('gbp'));
  const [showOptimiseTripodRemoval, setShowOptimiseTripodRemoval] = useState<boolean>(false);
  const [callPanoramasService, setCallPanoramasService] = useState(false);

  useEffect(() => {
    if (config) {
      const currency = config.countryInfo;
      if (currency) {
        setCurrency(currency.currency.toLowerCase());
      }
    }
  }, [config]);

  useEffect(() => {
    if (sidebar.leadGeneration) {
      setProAccount(sidebar.autoEnhance);
    }
  }, [sidebar]);

  useEffect(() => {
    setNewAiSettings(aiSettings);
  }, [aiSettings]);

  const saveReprocessPanoramas = async (tempState?: ITourSettings) => {
    const state = tempState ? tempState : newAiSettings;
    if (!state) {
      return;
    }
    setShowOptimiseTripodRemoval(false);
    setLoaded(false);
    try {
      await updateTourSettings(tour.id, state);
      toggleNotification(
        EnumNotificationType.Info,
        'Just a few more seconds. The images are being reprocessed with the settings changes made',
        10000,
      );
      changeLeftSidebar('');
      setCallPanoramasService(true);
      setLoaded(true);
    } catch (error) {
      const err = error as INewError;
      if (err.getMessage().includes('Some panoramas are still processing. Please wait')) {
        toggleNotification(
          EnumNotificationType.Warning,
          'Just a few more seconds. The images are being reprocessed with the settings changes made',
        );
      } else {
        toggleNotification(EnumNotificationType.Error, err.getMessage() || 'Error changing tour settings');
      }
      handleError(err, handleLogoutState);
      setLoaded(true);
    }
  };

  // When detecting processing panoramas stop and let panorama-slider component take over
  // AVOID manual refreshing of the page
  useEffect(() => {
    const findProcessing = panoramaData?.find((pano: any) => pano.status < 5);
    if (findProcessing) {
      setCallPanoramasService(false);
    }
  }, [panoramaData]);

  useInterval(
    () => {
      // Refreshes panorama list every 5 seconds until it status changes
      updatePanoramaList();
    },
    callPanoramasService ? 3000 : null,
  );

  const upgradeToProPlan = async () => {
    try {
      const createPaymentSession = await createSubscriptionPortal(currency);
      if (createPaymentSession?.redirectUrl) {
        // redirect to payment checkout
        window.location.href = createPaymentSession.redirectUrl;
      }
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      toggleNotification(EnumNotificationType.Error, linguiTranslateToString('There was an issue creating payment session'));
    }
  };

  return (
    <>
      {showOptimiseTripodRemoval && (
        <ModalOptimiseTripod
          tripodMaskRatio={Number(aiSettings.tripodMaskRatio)}
          // tripodMaskShape={aiSettings.tripodMaskShape || EnumShape.Circle}
          closeModal={setShowOptimiseTripodRemoval}
          saveReprocessPanoramas={saveReprocessPanoramas}
          currentPanorama={currentPanorama}
          newAiSettings={newAiSettings}
          setNewAiSettings={setNewAiSettings}
        />
      )}
      <div className='settings-modal-container'>
        <div className='update-tour-container'>
          <div className='display-flex flex-center flex-column' style={{ gap: '5px', cursor: 'default' }}>
            <div className='settings-choose-logo-text'>
              <Trans id='Change the virtual tour configuration for ' />
              {(tour && tour.name) || ''}.
            </div>
            <div className='logo-tripod-requirements'>
              <Trans id='Choose which options you want to enable or disable.' />
            </div>
          </div>
          <div>
            <div className='viewer-config-container'>
              <div className='settings-config-block-ai-features'>
                <div className='settings-config-option'>
                  <div style={{ cursor: 'default' }}>
                    <Trans id='Auto-enhance all images' render={({ translation }) => <div className='field-text'>{translation}</div>} />
                    <Trans
                      id='Automatically enhance all images'
                      render={({ translation }) => <div className='field-subtext'>{translation}</div>}
                    />
                  </div>
                  {sidebar.autoEnhance ? (
                    <div className='field-input-container'>
                      <label className='switch-settings'>
                        <input
                          id='autoEnhance'
                          type='checkbox'
                          checked={newAiSettings?.autoEnhance || false}
                          onClick={() => {
                            if (newAiSettings?.autoEnhance) {
                              //Report Custom Category and Event
                              ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.DisableEnhance);
                            } else {
                              //Report Custom Category and Event
                              ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.EnableEnhance);
                            }
                          }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setNewAiSettings({
                              ...newAiSettings,
                              autoEnhance: e.target.checked === true ? true : false,
                            })
                          }
                        />
                        <span id='slider-auto-start-tour' className='slider-settings round-settings'>
                          <span className={`slider-text-${newAiSettings?.autoEnhance ? 'on' : 'off'}`}>
                            {newAiSettings?.autoEnhance ? 'ON' : 'OFF'}
                          </span>
                        </span>
                      </label>
                    </div>
                  ) : (
                    <div className='field-input-container'>
                      <label className='switch-settings'>
                        <input id='enableTripod' type='checkbox' disabled={!proAccount} />
                        <span id='slider-auto-start-tour' className='slider-settings-disable round-settings'></span>
                      </label>
                    </div>
                  )}
                </div>
                <>
                  <div className='settings-config-option'>
                    <div style={{ cursor: 'default' }}>
                      <Trans id='Auto-blur' render={({ translation }) => <div className='field-text'>{translation}</div>} />
                      <Trans
                        id='Automatically detect and blur faces in all your images'
                        render={({ translation }) => <div className='field-subtext'>{translation}</div>}
                      />
                    </div>
                    {sidebar.autoBlur ? (
                      <div className='field-input-container'>
                        <label className='switch-settings'>
                          <input
                            id='autoBlur'
                            type='checkbox'
                            checked={newAiSettings?.autoBlur || false}
                            onClick={() => {
                              if (newAiSettings?.autoBlur) {
                                //Report Custom Category and Event
                                ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.DisableBlur);
                              } else {
                                //Report Custom Category and Event
                                ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.EnableBlur);
                              }
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setNewAiSettings({
                                ...newAiSettings,
                                autoBlur: e.target.checked === true ? true : false,
                              })
                            }
                          />
                          <span id='slider-share-location' className='slider-settings round-settings'>
                            <span className={`slider-text-${newAiSettings?.autoBlur ? 'on' : 'off'}`}>
                              {newAiSettings?.autoBlur ? 'ON' : 'OFF'}
                            </span>
                          </span>
                        </label>
                      </div>
                    ) : (
                      <div className='field-input-container'>
                        <label className='switch-settings'>
                          <input id='enableTripod' type='checkbox' disabled={!proAccount} />
                          <span id='slider-auto-start-tour' className='slider-settings-disable round-settings'></span>
                        </label>
                      </div>
                    )}
                  </div>
                </>
              </div>
              <div className='settings-config-block-ai-features'>
                <>
                  <div className='settings-config-option'>
                    <div style={{ cursor: 'default' }}>
                      <Trans id='Auto tripod removal' render={({ translation }) => <div className='field-text'>{translation}</div>} />
                      <Trans
                        id='Automatically remove the tripod from the 
					            bottom of all your images '
                        render={({ translation }) => <div className='field-subtext'>{translation}</div>}
                      />
                    </div>
                    {sidebar.autoTripodRemoval ? (
                      <div className='field-input-container'>
                        <label className='switch-settings'>
                          <input
                            id='tripodRemoved'
                            type='checkbox'
                            checked={newAiSettings?.tripodRemoved || false}
                            onClick={() => {
                              if (newAiSettings?.tripodRemoved) {
                                //Report Custom Category and Event
                                ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.DisableTripodRemoved);
                              } else {
                                //Report Custom Category and Event
                                ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.EnableTripodRemoved);
                              }
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setNewAiSettings({
                                ...newAiSettings,
                                tripodRemoved: e.target.checked === true ? true : false,
                              })
                            }
                          />
                          <span id='slider-auto-start-tour' className='slider-settings round-settings'>
                            <span className={`slider-text-${newAiSettings?.tripodRemoved ? 'on' : 'off'}`}>
                              {newAiSettings?.tripodRemoved ? 'ON' : 'OFF'}
                            </span>
                          </span>
                        </label>
                      </div>
                    ) : (
                      <div className='field-input-container'>
                        <label className='switch-settings'>
                          <input id='enableTripod' type='checkbox' disabled={!proAccount} />
                          <span id='slider-auto-start-tour' className='slider-settings-disable round-settings'></span>
                        </label>
                      </div>
                    )}
                  </div>
                </>
                <div className='settings-config-option'>
                  <div style={{ cursor: 'default' }}>
                    <Trans id='Auto-alignment' render={({ translation }) => <div className='field-text'>{translation}</div>} />
                    <Trans
                      id='Automatically straightnen the images on upload'
                      render={({ translation }) => <div className='field-subtext'>{translation}</div>}
                    />
                  </div>
                  {sidebar.autoAlignment ? (
                    <div className='field-input-container'>
                      <label className='switch-settings'>
                        <input
                          id='autoAligned'
                          type='checkbox'
                          checked={newAiSettings?.autoAligned || false}
                          onClick={() => {
                            if (newAiSettings?.autoAligned) {
                              //Report Custom Category and Event
                              ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.DisableAutoAligned);
                            } else {
                              //Report Custom Category and Event
                              ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.EnableAutoAligned);
                            }
                          }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setNewAiSettings({
                              ...newAiSettings,
                              autoAligned: e.target.checked === true ? true : false,
                            })
                          }
                        />
                        <span id='slider-share-location' className='slider-settings round-settings'>
                          <span className={`slider-text-${newAiSettings?.autoAligned ? 'on' : 'off'}`}>
                            {newAiSettings?.autoAligned ? 'ON' : 'OFF'}
                          </span>
                        </span>
                      </label>
                    </div>
                  ) : (
                    <div className='field-input-container'>
                      <label className='switch-settings'>
                        <input id='enableTripod' type='checkbox' disabled={!proAccount} />
                        <span id='slider-auto-start-tour' className='slider-settings-disable round-settings'></span>
                      </label>
                    </div>
                  )}
                </div>
              </div>
              <div className='settings-config-block-ai-features'>
                {sidebar.multiRes && (
                  <>
                    <div className='settings-config-option'>
                      <div style={{ cursor: 'default' }}>
                        <Trans id='Multi-resolution' render={({ translation }) => <div className='field-text'>{translation}</div>} />
                        <Trans
                          id='Converts panorama image into tiles to display a higher resolution image'
                          render={({ translation }) => <div className='field-subtext'>{translation}</div>}
                        />
                      </div>
                      <div className='field-input-container'>
                        <label className='switch-settings'>
                          <input
                            id='multiResolution'
                            type='checkbox'
                            checked={newAiSettings?.multiResolution || false}
                            onClick={() => {
                              if (newAiSettings?.multiResolution) {
                                //Report Custom Category and Event
                                ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.DisableMultires);
                              } else {
                                //Report Custom Category and Event
                                ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.EnableMultires);
                              }
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setNewAiSettings({
                                ...newAiSettings,
                                multiResolution: e.target.checked === true ? true : false,
                              })
                            }
                          />
                          <span id='slider-auto-start-tour' className='slider-settings round-settings'>
                            <span className={`slider-text-${newAiSettings?.multiResolution ? 'on' : 'off'}`}>
                              {newAiSettings?.multiResolution ? 'ON' : 'OFF'}
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </>
                )}
                <div className='settings-config-option-tripod'>
                  <button className='optimise-tripod-button button-hover' onClick={() => setShowOptimiseTripodRemoval(true)}>
                    <Trans id='Optimise tripod removal' render={({ translation }) => <div className='field-subtext'>{translation}</div>} />
                  </button>
                </div>
              </div>
            </div>
          </div>
          {proAccount ? (
            <div className='modal-btn-container-branding'>
              <button
                data-testid='switch-state-button'
                id='switch-state-button'
                className='create-tour-btn-size btn-new-design btn-new-active button-hover'
                onClick={() => {
                  saveReprocessPanoramas();
                }}
              >
                <Trans id='Save changes' />
              </button>
            </div>
          ) : (
            <div className='save-information-button-container'>
              <button
                id='save-btn-your-branding'
                className='save-information-button button-active'
                onClick={() => {
                  ReportCustomEvent(EnumCategory.MainMenu, EnumEvent.UpdateToProAccount);
                  upgradeToProPlan();
                }}
              >
                <Trans id='Upgrade account to PRO to unlock this feature' />
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditAiFeatures;
