import { defineMessage } from '@lingui/macro';
import { Trans } from '@lingui/react';
import React, { useState, useEffect } from 'react';
import { EnumCategory, EnumEvent } from '../../../google-analytics-track-list';
import { ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { leadInformation, leadType } from '../../editing-viewer/modals/settings/lead-generation';
import { ISidebar } from '../../editing-viewer/viewer-canvas';
import CustomSelect from '../../utils/custom-select/custom-select';
import { linguiTranslateToString } from '../../utils/lingui-utils/utils-lingui';
import ModalConfirmApplyAll from './modal-confirm-apply-all';
import { ILeadConfig } from './settings';
import { useConfig } from '../../utils/user-config-context/user-config-context';
import { createSubscriptionPortal } from '../../../api-helper/api-payment';
import { handleError } from '../../../api-helper/api-error-handling';
import { useAuth } from '../../../auth-routes/auth-context';
import Notification, { EnumNotificationSize, EnumNotificationType } from '../../utils/notifications/notification';

interface IProps {
  sidebar: ISidebar;
  leadConfiguration: ILeadConfig;
  setLeadConfiguration: (option: ILeadConfig) => void;
  toggleModal: (state: boolean) => void;
  itemModalOpen: boolean;
  onSaveLeads: (global: boolean) => void;
}

const LeadsGeneration: React.FC<IProps> = ({
  sidebar,
  leadConfiguration,
  setLeadConfiguration,
  toggleModal,
  itemModalOpen,
  onSaveLeads,
}) => {
  const [showFormVisualisationCustomDropDown, setShowFormVisualisationCustomDropDown] = useState<boolean>(false);
  const [showFormConfigCustomDropDown, setShowFormConfigCustomDropDown] = useState<boolean>(false);
  const [proAccount, setProAccount] = useState(false);
  const config = useConfig();
  const [currency, setCurrency] = useState(linguiTranslateToString('gbp'));
  const { handleLogoutState } = useAuth();
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [typeNotification, setTypeNotification] = useState<EnumNotificationType>();
  const [notificationMessage, setNotificationMessage] = useState<string>('');

  const toggleNotification = (type: EnumNotificationType, message?: string) => {
    setTypeNotification(type);
    setNotificationMessage(message || linguiTranslateToString('There was an error please try again'));
    setShowNotification(true);
  };

  useEffect(() => {
    if (config) {
      const currency = config.countryInfo;
      if (currency) {
        setCurrency(currency.currency.toLowerCase());
      }
    }
  }, [config]);

  useEffect(() => {
    if (sidebar.leadGeneration) {
      setProAccount(sidebar.leadGeneration);
    }
  }, [sidebar]);

  const toggleFormVisualisationDropdown = (option?: boolean) => {
    if (proAccount) {
      if (option) {
        setShowFormVisualisationCustomDropDown(option);
      } else {
        setShowFormVisualisationCustomDropDown(!showFormVisualisationCustomDropDown);
      }
    }
  };

  const toggleFormConfigDropdown = (option?: boolean) => {
    if (proAccount) {
      if (option) {
        setShowFormConfigCustomDropDown(option);
      } else {
        setShowFormConfigCustomDropDown(!showFormConfigCustomDropDown);
      }
    }
  };

  const onChangeFormVisualisation = (option: string) => {
    let selectedOption = {
      value: '',
      label: '',
    };
    leadType.map(opt => {
      if (opt.label === option) {
        selectedOption.value = opt.value;
        selectedOption.label = opt.label;
      }
      return selectedOption;
    });
    setLeadConfiguration({ ...leadConfiguration, leadsType: Number(selectedOption?.value) });
    toggleFormVisualisationDropdown();
  };

  const onChangeFormConfig = (option: string) => {
    let selectedOption = {
      value: '',
      label: '',
    };
    leadInformation.map(opt => {
      if (opt.label === option) {
        selectedOption.value = opt.value;
        selectedOption.label = opt.label;
      }
      return selectedOption;
    });
    setLeadConfiguration({ ...leadConfiguration, leadsConfig: Number(selectedOption?.value) });
    toggleFormConfigDropdown();
  };

  const upgradeToProPlan = async () => {
    try {
      const createPaymentSession = await createSubscriptionPortal(currency);
      if (createPaymentSession?.redirectUrl) {
        // redirect to payment checkout
        window.location.href = createPaymentSession.redirectUrl;
      }
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      toggleNotification(EnumNotificationType.Error, linguiTranslateToString('There was an issue creating payment session'));
    }
  };

  return (
    <>
      {showNotification && (
        <Notification
          showNotification={showNotification}
          size={EnumNotificationSize.Large}
          type={typeNotification || EnumNotificationType.Info}
          message={notificationMessage}
          setShowNotification={setShowNotification}
          fullScreen={true}
        />
      )}
      <div className='settings-information-container'>
        {itemModalOpen && <ModalConfirmApplyAll setShowDeleteModal={toggleModal} applyAll={onSaveLeads} />}
        <div className='settings-information-layout'>
          <div className='panelhook-container'>
            <div>
              <div>
                <div className='leads-config-block'>
                  <div className='settings-config-option'>
                    <div style={{ color: !proAccount ? '#818AA6' : '', cursor: 'default' }}>
                      <Trans id='Lead generation form' render={({ translation }) => <div className='field-text'>{translation}</div>} />
                      <Trans
                        id='Enable the lead generation form to capture leads'
                        render={({ translation }) => (
                          <div style={{ color: !proAccount ? '#818AA6' : '' }} className='field-subtext'>
                            {translation}
                          </div>
                        )}
                      />
                    </div>
                    <div className='field-input-container'>
                      {proAccount ? (
                        <label className='switch-settings'>
                          <input
                            id='leadsGeneration'
                            type='checkbox'
                            checked={leadConfiguration?.enableLeadGenerationForm}
                            disabled={!proAccount}
                            onClick={() => {
                              if (leadConfiguration?.enableLeadGenerationForm) {
                                //Report Custom Category and Event
                                ReportCustomEvent(EnumCategory.MainMenu, EnumEvent.DisableLeadGenerationForm);
                              } else {
                                //Report Custom Category and Event
                                ReportCustomEvent(EnumCategory.MainMenu, EnumEvent.EnableLeadGenerationForm);
                              }
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setLeadConfiguration({
                                ...leadConfiguration,
                                enableLeadGenerationForm: e.target.checked === true ? true : false,
                              })
                            }
                          />
                          <span id='slider-leads' className='slider-settings round-settings'>
                            <span className={`slider-text-${leadConfiguration.enableLeadGenerationForm ? 'on' : 'off'}`}>
                              {leadConfiguration.enableLeadGenerationForm ? 'ON' : 'OFF'}
                            </span>
                          </span>
                        </label>
                      ) : (
                        <label className='switch-settings'>
                          <input
                            id='enableTripod'
                            type='checkbox'
                            disabled={!proAccount}
                            checked={leadConfiguration?.enableLeadGenerationForm}
                          />
                          <span id='slider-auto-start-tour' className='slider-settings-disable round-settings'></span>
                        </label>
                      )}
                    </div>
                  </div>
                  <div className='settings-config-option' />
                </div>
                <div className='leads-config-block'>
                  <div className='leads-generation-option' style={{ color: !proAccount ? '#818AA6' : '', cursor: 'default' }}>
                    <Trans
                      id='Lead email address'
                      render={({ translation }) => <div className={proAccount ? 'field-text' : 'field-text-disable'}>{translation}</div>}
                    />
                    <div>
                      <Trans
                        id={'Where should we send your lead to?'}
                        render={({ translation }) => (
                          <input
                            onClick={() => {
                              //Report Custom Category and Event
                              ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.EnableDisableGoogleAnalyticsIntegration);
                            }}
                            placeholder={String(translation)}
                            style={{ width: '80%' }}
                            disabled={!proAccount}
                            value={leadConfiguration.email}
                            className={`general-field-input-text ${
                              leadConfiguration.email !== ''
                                ? 'general-field-complete'
                                : proAccount
                                ? 'general-field-input-text'
                                : 'general-field-input-disable'
                            }`}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setLeadConfiguration({ ...leadConfiguration, email: e.target.value });
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className='leads-generation-option' style={{ color: !proAccount ? '#818AA6' : '', cursor: 'default' }}>
                    <Trans
                      id='Form configuration'
                      render={({ translation }) => (
                        <div
                          className={
                            proAccount
                              ? `field-text 
                              
                              
                            ${showFormConfigCustomDropDown ? 'field-text-label' : 'field-text'} `
                              : 'field-text-disable'
                          }
                        >
                          {translation}
                        </div>
                      )}
                    />
                    <div>
                      <Trans
                        id={'Choose your preferred config'}
                        render={({ translation }) => (
                          <CustomSelect
                            value={linguiTranslateToString(
                              leadInformation.find(a => a.value === String(leadConfiguration.leadsConfig))?.label || '',
                            )}
                            options={[
                              defineMessage({ message: 'Name, Email, Phone, Message' }),
                              defineMessage({ message: 'Name, Email, Message' }),
                              defineMessage({ message: 'Name, Phone, Message' }),
                              defineMessage({ message: 'Name,Email' }),
                              defineMessage({ message: 'Name, Phone' }),
                            ]}
                            changeDropdownValue={onChangeFormConfig}
                            showCustomDropDown={showFormConfigCustomDropDown}
                            setShowCustomDropDown={toggleFormConfigDropdown}
                            customZIndex={2}
                            customClass={
                              proAccount
                                ? `general-field-input custom-select-leads-generation ${
                                    leadInformation.find(a => a.value === String(leadConfiguration.leadsConfig))?.label
                                      ? ''
                                      : 'general-field-input-empty'
                                  } ${showFormConfigCustomDropDown ? 'general-field-focus' : 'general-field-input'} `
                                : 'general-field-input-disable custom-select-leads-generation'
                            }
                            customArrow={'custom-arrow-leads-generation'}
                            customDropdown={'custom-dropdown-leads-generation'}
                            placeholder={String(translation)}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className='leads-generation-option' style={{ color: !proAccount ? '#818AA6' : '', cursor: 'default' }}>
                    <Trans
                      id='Form visualisation'
                      render={({ translation }) => (
                        <div
                          className={
                            proAccount
                              ? `field-text ${showFormVisualisationCustomDropDown ? 'field-text-label' : 'field-text'} `
                              : 'field-text-disable'
                          }
                        >
                          {translation}
                        </div>
                      )}
                    />
                    <div>
                      <Trans
                        id={"How you'd like the form displayed"}
                        render={({ translation }) => (
                          <CustomSelect
                            value={linguiTranslateToString(
                              leadType.find(a => a.value === String(leadConfiguration.leadsType))?.label || '',
                            )}
                            options={[defineMessage({ message: 'Optional' }), defineMessage({ message: 'Compulsory' })]}
                            changeDropdownValue={onChangeFormVisualisation}
                            showCustomDropDown={showFormVisualisationCustomDropDown}
                            setShowCustomDropDown={toggleFormVisualisationDropdown}
                            customZIndex={2}
                            customClass={
                              proAccount
                                ? `general-field-input custom-select-leads-generation ${
                                    leadType.find(a => a.value === String(leadConfiguration.leadsType))?.label
                                      ? ''
                                      : 'general-field-input-empty'
                                  } ${showFormVisualisationCustomDropDown ? 'general-field-focus' : 'general-field-input'}`
                                : 'general-field-input-disable custom-select-leads-generation'
                            }
                            customArrow={'custom-arrow-leads-generation'}
                            customDropdown={'custom-dropdown-leads-generation'}
                            placeholder={String(translation)}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className='leads-config-block'>
                  <div className='leads-generation-option' style={{ color: !proAccount ? '#818AA6' : '', cursor: 'default' }}>
                    <Trans
                      id='Form message (optional)'
                      render={({ translation }) => <div className={proAccount ? 'field-text ' : 'field-text-disable'}>{translation}</div>}
                    />
                    <div>
                      <Trans
                        id={
                          "What do you want to say to your potential leads Example: use the form below to request more information about this property; Your information won't be shared with anyone else"
                        }
                        render={({ translation }) => (
                          <textarea
                            onClick={() => {
                              //Report Custom Category and Event
                              ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.EnableDisableGoogleAnalyticsIntegration);
                            }}
                            placeholder={String(translation)}
                            disabled={!proAccount}
                            style={{ width: '94%', height: '200px' }}
                            className={proAccount ? 'general-field-input-text-area' : 'general-field-input-disable'}
                            onChange={(e: any) => setLeadConfiguration({ ...leadConfiguration, leadsMessage: e.target.value })}
                          ></textarea>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {proAccount ? (
              <div className='save-information-button-container'>
                <div>
                  <button
                    id='apply-all-lead'
                    className='save-information-button button-white button-hover'
                    onClick={(e: React.MouseEvent) => {
                      e.preventDefault();
                      toggleModal(true);
                    }}
                  >
                    <Trans id='Apply to all' />
                  </button>
                </div>
                <div>
                  <button
                    id='save-btn-Lead-generation'
                    className='save-information-button button-active button-hover'
                    onClick={(e: React.MouseEvent) => {
                      const global = false;
                      onSaveLeads(global);
                    }}
                  >
                    <Trans id='Save changes' />
                  </button>
                </div>
              </div>
            ) : (
              <div className='save-information-button-container'>
                <button
                  id='save-btn-your-branding'
                  className='save-information-button button-active'
                  onClick={() => {
                    ReportCustomEvent(EnumCategory.MainMenu, EnumEvent.UpdateToProAccount);
                    upgradeToProPlan();
                  }}
                >
                  <Trans id='Upgrade account to PRO to unlock this feature' />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadsGeneration;
