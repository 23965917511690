import { defineMessage } from '@lingui/macro';
import { Trans } from '@lingui/react';
import React, { useEffect, useState } from 'react';
import { ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../../google-analytics-track-list';
import CustomSelect from '../../utils/custom-select/custom-select';
import DataTable from '../../utils/data-table/data-table';
import Pagination from '../../utils/pagination/pagination';
import fileDownload from 'js-file-download';
import { getLiveShareLeads, getLeadsLiveSharing } from '../../../api-helper/api-analytics';
import { handleError } from '../../../api-helper/api-error-handling';
import { getUser } from '../../../api-helper/api-user';
import { linguiTranslateToString } from '../../utils/lingui-utils/utils-lingui';
import { EnumNotificationType } from '../../utils/notifications/notification';
import { ILeadLiveSharing } from '../../../api-helper/interface/interfaces';
import { useAuth } from '../../../auth-routes/auth-context';
import { ILabels, IPageSelection } from './analytics';
import { useConfig } from '../../utils/user-config-context/user-config-context';
import noLeadsLarge from '../../../images/empty-states-illustrations/analytics-illustration-free.svg';
import { createSubscriptionPortal } from '../../../api-helper/api-payment';

interface IProps {
  tabIndex: number | undefined;
  proAccount: boolean;
  setLoadedContent: (state: boolean) => void;
  setShowNotification: (state: boolean) => void;
  dateRangeServiceSelected: string;
  toggleNotification: (type: EnumNotificationType, message?: string) => void;
  dateRangeSelected: string;
  changeDateRangeHandler: (myValue: string) => void;
  loadedContent: boolean;
  showCustomDropDownSortByLive: boolean;
  toggleCustomDropdownSortByLive: (option?: boolean) => void;
  notificationNoData: (type: string) => JSX.Element | undefined;
  labels: ILabels[];
}

const LiveSharingLeads: React.FC<IProps> = ({
  tabIndex,
  proAccount,
  setLoadedContent,
  setShowNotification,
  dateRangeServiceSelected,
  toggleNotification,
  dateRangeSelected,
  changeDateRangeHandler,
  loadedContent,
  showCustomDropDownSortByLive,
  toggleCustomDropdownSortByLive,
  notificationNoData,
  labels,
}) => {
  const [displayModule, setDisplayModule] = useState<boolean | undefined>();
  const [leadsLiveSharePageCount, setLeadsLiveSharePageCount] = useState<number>(0);
  const [usersLiveShare, setUsersLiveShare] = useState<ILeadLiveSharing[]>();
  const [isDataLiveSharing, setIsDataLiveSharing] = useState<boolean>(false);
  const [leadsLiveSharingPage, setLeadsLiveSharingPage] = useState<number>(0);
  const { handleLogoutState } = useAuth();
  const config = useConfig();
  const [currency, setCurrency] = useState(linguiTranslateToString('gbp'));

  useEffect(() => {
    if (config) {
      const currency = config.countryInfo;
      if (currency) {
        setCurrency(currency.currency.toLowerCase());
      }
    }
  }, [config]);

  useEffect(() => {
    if (proAccount !== undefined) {
      setDisplayModule(proAccount);
    }
  }, [proAccount]);

  useEffect(() => {
    if (displayModule !== undefined) {
      if (displayModule) {
        if (tabIndex === 2) {
          setLoadedContent(false);
          getLeadsLiveShareInfo();
        }
      } else {
        setLoadedContent(true);
      }
    }
  }, [dateRangeServiceSelected, tabIndex, leadsLiveSharingPage, displayModule]);

  const handleLeadsPaginationLiveSharingSearch = (pageSelected: IPageSelection) => {
    setLeadsLiveSharingPage(pageSelected.selected);
  };

  const getLeadsLiveShareInfo = async (previousPagination?: number) => {
    setLoadedContent(true);
    setShowNotification(false);
    try {
      const pagePagination = previousPagination ? previousPagination : leadsLiveSharingPage;
      const userInfo = await getUser();
      const data = await getLiveShareLeads(pagePagination, dateRangeServiceSelected, userInfo.id);

      setLeadsLiveSharePageCount(data.pageCount);
      setUsersLiveShare(data.results);

      if (data.results.length > 0) {
        setIsDataLiveSharing(true);
      } else {
        setIsDataLiveSharing(false);
        toggleNotification(EnumNotificationType.Info, linguiTranslateToString('No data to display for the selected time frame'));
      }
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      toggleNotification(EnumNotificationType.Error, 'There was an error loading the data');
    } finally {
      setLoadedContent(true);
    }
  };

  const downloadLiveShareCsv = async (e: React.MouseEvent) => {
    e.preventDefault();
    setLoadedContent(false);
    try {
      const id = config.userId;
      const response = await getLeadsLiveSharing(dateRangeServiceSelected, id);
      if (response && response.data) {
        const fileName = 'live_share_leads.csv';
        fileDownload(response.data, fileName);
      }
      setLoadedContent(true);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      setLoadedContent(true);
    }
  };

  const upgradeToProPlan = async () => {
    try {
      const createPaymentSession = await createSubscriptionPortal(currency);
      if (createPaymentSession?.redirectUrl) {
        // redirect to payment checkout
        window.location.href = createPaymentSession.redirectUrl;
      }
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      toggleNotification(EnumNotificationType.Error, linguiTranslateToString('There was an issue creating payment session'));
    }
  };

  return (
    <>
      <div className='analytics-leads-generated-container'>
        <div className='container-custom-select'>
          <div className='controls'>
            <div className='sort-by-date-container'>
              <div className='info-tag-title' style={!displayModule ? { color: '#818aa6' } : {}}>
                <Trans id='Select a date range' />
              </div>
              <CustomSelect
                customDropdownId={'leadShareDropdown'}
                value={dateRangeSelected}
                options={[
                  defineMessage({ message: 'Last 24 hours' }),
                  defineMessage({ message: 'This week' }),
                  defineMessage({ message: 'Last Week' }),
                  defineMessage({ message: 'This month' }),
                  defineMessage({ message: 'Last month' }),
                  defineMessage({ message: 'This year' }),
                  defineMessage({ message: 'Last year' }),
                ]}
                changeDropdownValue={changeDateRangeHandler}
                showCustomDropDown={showCustomDropDownSortByLive}
                setShowCustomDropDown={toggleCustomDropdownSortByLive}
                customClass={
                  !displayModule
                    ? 'general-field-input-disable custom-select-leads-generation-viewer-settings'
                    : `general-field-input ${showCustomDropDownSortByLive ? 'general-field-focus' : ''}`
                }
              />
            </div>
          </div>
          <div className='button-container'>
            <button
              className={!isDataLiveSharing ? 'disable-btn-download-analytics' : 'btn-download-analytics button-hover'}
              disabled={!isDataLiveSharing}
              onClick={(e: React.MouseEvent) => {
                downloadLiveShareCsv(e);
                ReportCustomEvent(EnumCategory.DownloadMenu, EnumEvent.DownloadAllLiveSharingLeads);
              }}
            >
              <Trans id='Download all leads' />
            </button>
          </div>
        </div>
        {!isDataLiveSharing && loadedContent && displayModule ? (
          <div>{notificationNoData('leadsSharing')}</div>
        ) : loadedContent && !displayModule ? (
          <div className='free-account-leads'>
            <div>
              <img src={noLeadsLarge} alt='illustration' className='no-leads-large-img' style={{ position: 'relative' }} />
            </div>
            <div className='save-information-button-container'>
              <button
                onClick={() => {
                  ReportCustomEvent(EnumCategory.MainMenu, EnumEvent.UpdateToProAccount);
                  upgradeToProPlan();
                }}
                id='save-btn-your-branding'
                className='save-information-button button-active'
              >
                <Trans id='Upgrade account to PRO to unlock this feature' />
              </button>
            </div>
          </div>
        ) : (
          <>
            {loadedContent ? (
              <div className='leads-analytics-container'>
                <DataTable
                  accounts={usersLiveShare}
                  labels={labels}
                  isLoaded={loadedContent}
                  labelContainerStyles={'analytics-dt-label-container'}
                  labelStyles={'analytics-dt-label'}
                  dataContainerStyles={'analytics-dt-data-container'}
                  dataStyles={'analytics-dt-data'}
                />
              </div>
            ) : (
              <div></div>
            )}
          </>
        )}
      </div>
      <div className='pagination-container'>
        {usersLiveShare && usersLiveShare.length !== 0 && (
          <Pagination
            page={leadsLiveSharingPage}
            pageCount={leadsLiveSharePageCount}
            handlePaginationSearch={handleLeadsPaginationLiveSharingSearch}
          />
        )}
      </div>
    </>
  );
};

export default LiveSharingLeads;
