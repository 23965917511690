import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/react';
import { IPropsTourCards } from './interface';
import { formatBytes } from '../bytes-converter/bytes-converter';
import { ReactComponent as MenuDots } from '../../../images/dashboard-icons/ic-menu-dots-inactive.svg';
import { ReactComponent as MenuDotsActive } from '../../../images/dashboard-icons/card-icons/btn-hover.svg';
import { ReactComponent as MenuDotsDisable } from '../../../images/dashboard-icons/card-icons/menu-dot-disable.svg';
import defaultImage from '../../../images/empty.svg';
import passwordLock from '../../../images/dashboard-icons/password-lock.svg';
import leadsGeneration from '../../../images/dashboard-icons/lead-generation.svg';
import iconDollhouse from '../../../images/dashboard-icons/icon-dollhouse-card.svg';
import iconFloorplan from '../../../images/dashboard-icons/icon-floorplan-card.svg';
import displayLocation from '../../../images/dashboard-icons/display-location.svg';
import imageIconCard from '../../../images/dashboard-icons/card-icons/icon-img-card.svg';
import timeIconCard from '../../../images/dashboard-icons/card-icons/icon-img-time.svg';
import linkIconCard from '../../../images/dashboard-icons/card-icons/link-icon-card.svg';
import locationIcon from '../../../images/dashboard-icons/card-icons/icon-location.svg';
import viewsIconCard from '../../../images/dashboard-icons/card-icons/icon-view-card.svg';
import profileIcon from '../../../images/dashboard-icons/profile-icon.svg';
import './tour-views-dropdown-menu.scss';
import './tour-rows.scss';
import './tour-rows-new.scss';
import { ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../../google-analytics-track-list';
import { ITours } from '../../../api-helper/interface/interfaces';
import dollHouse from '../../../images/dashboard-icons/dollhouse-icon.svg';
import liveSharing from '../../../images/dashboard-icons/live-sharing.svg';
import { useConfig } from '../user-config-context/user-config-context';
import { createMeeting, createOrganiser } from '../../../api-helper/api-live-sharing';
import { linguiTranslateToString } from '../lingui-utils/utils-lingui';

export interface IModules {
  [key: string]: boolean;
}

const TourRows: React.FC<IPropsTourCards> = ({
  deleteArchived,
  page,
  keyword,
  tours,
  toursRef,
  showModal,
  archiveTours,
  restoreTours,
  duplicateATour,
  type,
  orderOptionName,
  orderOption,
  downloadAllPanoramas,
  showDollhouseTab,
  showOrderDollhouse,
  apiNoPayment,
}) => {
  const [onHoverDisplay, setOnHoverDisplay] = useState<string>('');
  const [activeMenu, setActiveMenu] = useState<boolean>(false);
  const [proActive, setProActive] = useState(false);
  const config = useConfig();

  useEffect(() => {
    if (config) {
      const liveShareModule = config.modules.find((a: IModules) => Object.keys(a)[0] === 'liveShare');
      if (liveShareModule?.liveShare) {
        setProActive(liveShareModule.liveShare);
      }
    }
  }, [config]);

  const getStandaloneViewerUrl = (tourReadCode: string, short: boolean) => {
    const standAloneViewerUrl = window.ENV.REACT_APP_STANDALONE_VIEWER_URL || process.env.REACT_APP_STANDALONE_VIEWER_URL;
    const url = standAloneViewerUrl + tourReadCode;

    if (short) {
      const shortUrl = standAloneViewerUrl.replace(/^https:\/\//i, '');
      return shortUrl && tourReadCode ? shortUrl + tourReadCode : '';
    } else {
      return url ? url : '';
    }
  };

  const addDefaultSrc = (ev: React.ChangeEvent<HTMLImageElement>) => {
    ev.target.src = defaultImage;
  };

  const createDirectLiveSharing = async (tourUrl: string, tourName: string) => {
    try {
      const createLiveMeeting = await createMeeting(tourUrl, tourName, config.userId);
      const [firstName, lastName] = config.fullName.split(' ');
      const organiserBody = {
        firstName,
        lastName,
        token: window.ENV.REACT_APP_CAPTCHA_BY_PASS || process.env.REACT_APP_CAPTCHA_BY_PASS,
        email: config.userEmail,
        phoneNumber: config.phoneNumber,
      };
      const createLiveOrganiser = await createOrganiser(createLiveMeeting.code, organiserBody);
      const frontURL = window.ENV.REACT_APP_LIVE_FRONT_URL || process.env.REACT_APP_LIVE_FRONT_URL;
      const url = `${frontURL}/directLink/${createLiveMeeting.code}?userId=${createLiveOrganiser.id}`;
      window.location.href = url;
    } catch (error) {}
  };

  return (
    <React.Fragment>
      {tours?.map((tour: ITours, index: number) => (
        <div id='tours-row' ref={element => (toursRef.current[index] = element)} key={index} className='tour-row'>
          <div
            className='row-image'
            onClick={() => {
              //Report Custom Category and Event
              ReportCustomEvent(EnumCategory.TourInformation, EnumEvent.OpenTourProfile);
            }}
          >
            {!restoreTours && (
              <div className='lock-leads-icons'>
                {tour.dollhouseExist && (
                  <button style={{ cursor: 'default' }} className='icon-on-card'>
                    <img title={linguiTranslateToString('Dollhouse exist')} src={iconDollhouse} alt='Dollhouse exist' />
                  </button>
                )}
                {tour.shareLocation === '1' && (
                  <button style={{ cursor: 'default' }} className='icon-on-card'>
                    <img title={linguiTranslateToString('Location displayed')} src={displayLocation} alt='Location displayed' />
                  </button>
                )}

                {tour.floorplanExist && (
                  <button style={{ cursor: 'default' }} className='icon-on-card'>
                    <img title={linguiTranslateToString('Floor plan')} src={iconFloorplan} alt='Floor plan' />
                  </button>
                )}
                {tour.protected && (
                  <button style={{ cursor: 'default' }} className=' icon-on-card'>
                    <img title={linguiTranslateToString('Password protected')} src={passwordLock} alt='Padlock password protect' />
                  </button>
                )}

                {tour.enableLeadGenerationForm === '1' && (
                  <button style={{ cursor: 'default' }} className='icon-on-card'>
                    <img title={linguiTranslateToString('Leads generation')} src={leadsGeneration} alt='Leads generation' />
                  </button>
                )}
              </div>
            )}
            <div className='edit-link'>
              <Link
                to={{
                  pathname: '/edit-tour/panoramas',
                  search: `?tourId=${tour.id}`,
                  state: {
                    ...tour,
                    paginationPage: page,
                    searchWord: keyword,
                    tourIndex: index,
                    displayDashboard: true,
                    orderOptionName: orderOptionName,
                    orderOption: orderOption,
                    dashboardView: 'rows',
                  },
                }}
              >
                <div className='img-icon-card'></div>
                <Trans id='Tour details' />
              </Link>
            </div>
            <img className='img-row-view' onError={addDefaultSrc} src={tour.mainPhotoUrl || defaultImage} alt='Tour preview' />
          </div>
          <div className='info-row-view'>
            <div className='container-title-info'>
              <div id='tour-name' className='tour-row-address'>
                <Link
                  style={{ textDecoration: 'none' }}
                  to={{ pathname: '/edit-tour/panoramas', search: `?tourId=${tour.id}`, state: tour }}
                >
                  {tour.name}
                </Link>
              </div>
              <div className='container-info-tour'>
                <div className='card-sub-info' style={{ paddingLeft: '0' }}>
                  <img className='img-icon' src={imageIconCard} alt='Tour number of images icon' />

                  <Trans
                    id='{numImages} images ({tourSize})'
                    values={{ numImages: tour.panoramaCount, tourSize: formatBytes(tour.tourSize || 0) }}
                    render={({ translation }) => <p className='text-sub-info'>{translation}</p>}
                  />
                </div>
                <div className='card-sub-info' style={{ borderRight: '0.5px solid #757575', borderLeft: '0.5px solid #757575' }}>
                  <img className='time-icon' src={timeIconCard} alt='last updated' />
                  <span className='text-sub-info'>
                    <Trans
                      id=' <0>{updatedTour}</0> at <1>{updatedTour}</1>'
                      values={{ updatedTour: tour.updatedAt }}
                      components={{ 0: <Moment format='DD/MM/YY'></Moment>, 1: <Moment format='HH:mm'></Moment> }}
                    />
                  </span>
                </div>
                <div className='card-sub-info'>
                  <img className='view-icon' src={viewsIconCard} alt='Tour number of views icon' />
                  <Trans
                    id='{numViews} views'
                    values={{ numViews: tour.views }}
                    render={({ translation }) => <p className='text-sub-info'>{translation}</p>}
                  />
                </div>
              </div>
            </div>
            <div className='container-bottom-info'>
              <div className='container-left-info'>
                <div className='info-details-location'>
                  <img style={{ marginLeft: '3px' }} className='details-icons' src={locationIcon} alt='Tour Location icon' />
                  <p title={tour.name} className='text-info-bottom-row'>
                    {tour.name}
                  </p>
                </div>
                <div
                  className='info-url'
                  onClick={() => {
                    //Report Custom Category and Event
                    ReportCustomEvent(EnumCategory.TourInformation, EnumEvent.OpenTourWithUrl);
                  }}
                >
                  <img className='details-icons' src={linkIconCard} alt='Tour url icon' />
                  {type === 'archived' || type === 'deleted' ? (
                    <p className='text-info-bottom-row invalid-link'>{getStandaloneViewerUrl(tour.readCode, false)}</p>
                  ) : (
                    <a
                      title={getStandaloneViewerUrl(tour.readCode, false)}
                      className='text-info-bottom-row'
                      href={getStandaloneViewerUrl(tour.readCode, false)}
                      rel='noreferrer'
                      target='_blank'
                    >
                      {getStandaloneViewerUrl(tour.readCode, false)}
                    </a>
                  )}
                </div>
              </div>
			  {tour.userEmail && <div className='container-left-info'>
				<div className='container-right-info' style={{width: '100%'}}>
					<div className='info-email-detail'>
					<img className='details-icons' src={profileIcon} alt='Tour Location icon' />
					<p className='text-info-bottom-doll'> {tour.userEmail} </p>
					</div>
				</div>
			  </div>}
            </div>
          </div>
          <div className='btns-row-view'>
            <div className='three-dot-options' style={proActive ? { padding: '12px 20px' } : {}}>
              <div
                className={`circle-container-dot ${
                  onHoverDisplay === tour.id
                    ? 'cirlce-container-focus'
                    : type === 'archived' || type === 'deleted'
                    ? 'circle-container-disable'
                    : 'circle-container-dot'
                }`}
              >
                <div className='dots-dropdown-parent-row'>
                  <div className='dots-dropdown'>
                    <button
                      className='remove-default'
                      onClick={() => {
                        setActiveMenu(true);
                        setOnHoverDisplay(tour.id);
                      }}
                      onMouseLeave={() => setActiveMenu(false)}
                    >
                      <div id='dots-menu' className='dots-btn'>
                        {onHoverDisplay === tour.id ? (
                          <MenuDotsActive />
                        ) : type === 'deleted' || type === 'archived' ? (
                          <MenuDotsDisable />
                        ) : (
                          <MenuDots />
                        )}
                      </div>
                    </button>
                    <div
                      onClick={() => {
                        setActiveMenu(!activeMenu);
                        setOnHoverDisplay(tour.id);
                      }}
                      onMouseLeave={() => setOnHoverDisplay('')}
                      className={`dots-dropdown-content ${onHoverDisplay === tour.id ? 'dots-dropdown-content-active' : ''}`}
                    >
                      <div className='dots-dropdown-menu'>
                        <span>
                          <button
                            onClick={() => {
                              ReportCustomEvent(EnumCategory.TourMenu, EnumEvent.ShareTour);
                              const modalContent = {
                                id: 'share-tour',
                                title: linguiTranslateToString('Share your virtual tour'),
                                tour: tour,
                              };
                              showModal(modalContent);
                            }}
                            className='remove-default dots-options'
                          >
                            <div className='icon-share-dropdown' />
                            <Trans id='Share tour' render={({ translation }) => <div className='dots-options-text'>{translation}</div>} />
                          </button>
                        </span>
                        <span>
                          <button
                            className='remove-default dots-options'
                            onClick={() => {
                              if (duplicateATour) {
                                ReportCustomEvent(EnumCategory.TourMenu, EnumEvent.DuplicateTour);
                                duplicateATour(tour.id);
                              }
                            }}
                          >
                            <div className='icon-duplicate-dropdown' />
                            <Trans id='Duplicate tour' render={({ translation }) => <p className='dots-options-text'>{translation}</p>} />
                          </button>
                        </span>
                        <span>
                          <button
                            className='remove-default dots-options'
                            onClick={() => {
                              ReportCustomEvent(EnumCategory.TourMenu, EnumEvent.DownloadEntireTour);
                              if (downloadAllPanoramas) {
                                downloadAllPanoramas(tour.id, tour.name);
                              }
                            }}
                          >
                            <div className='icon-download-dropdown' />
                            <Trans id='Download tour' render={({ translation }) => <p className='dots-options-text'>{translation}</p>} />
                          </button>
                        </span>
                        <span>
                          {!tour.protected ? (
                            <button
                              className='remove-default dots-options'
                              id='download-dashboard'
                              onClick={() => {
                                ReportCustomEvent(EnumCategory.TourMenu, EnumEvent.AddPasswordToTour);
                                const modalContent = {
                                  id: 'password-protect',
                                  title: linguiTranslateToString('Protect with password'),
                                  tourId: tour.id ? tour.id : '',
                                  tourName: tour.name ? tour.name : '',
                                };
                                showModal(modalContent);
                              }}
                            >
                              <div className='icon-lock-dropdown' />
                              <Trans
                                id='Password protect'
                                render={({ translation }) => <p className='dots-options-text'>{translation}</p>}
                              />
                            </button>
                          ) : (
                            <button
                              className='remove-default dots-options'
                              onClick={() => {
                                ReportCustomEvent(EnumCategory.TourMenu, EnumEvent.RemovePasswordToTour);
                                const modalContent = {
                                  id: 'remove-password-protect',
                                  title: linguiTranslateToString('Remove password'),
                                  tourId: tour.id ? tour.id : '',
                                  tourName: tour.name ? tour.name : '',
                                };
                                showModal(modalContent);
                              }}
                            >
                              <div className='icon-open-lock-dropdown' />
                              <Trans
                                id='Remove password'
                                render={({ translation }) => <p className='dots-options-text'>{translation}</p>}
                              />
                            </button>
                          )}
                        </span>
                        <span>
                          <button
                            className='remove-default dots-options'
                            onClick={() => {
                              if (archiveTours) {
                                ReportCustomEvent(EnumCategory.TourMenu, EnumEvent.ArchiveTour);
                                archiveTours(tour.id, true);
                              }
                            }}
                          >
                            <div className='icon-archive-dropdown' />
                            <Trans id='Archive tour' render={({ translation }) => <p className='dots-options-text'>{translation}</p>} />
                          </button>
                        </span>
                        <span>
                          <button
                            className='remove-default dots-options'
                            onClick={() => {
                              ReportCustomEvent(EnumCategory.TourMenu, EnumEvent.DeleteTour);
                              const modalContent = {
                                id: 'delete-tour',
                                title: linguiTranslateToString('Delete tour'),
                                tourId: tour.id ? tour.id : '',
                                tourName: tour.name ? tour.name : '',
                              };
                              showModal(modalContent);
                            }}
                          >
                            <div className='icon-delete-dropdown' />
                            <Trans id='Delete tour' render={({ translation }) => <p className='dots-options-text'>{translation}</p>} />
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {proActive && type !== 'archived' && type !== 'deleted' && (
              <div
                className='start-live-sharing-button'
                onClick={() => createDirectLiveSharing(getStandaloneViewerUrl(tour.readCode, false), tour.name)}
              >
                <img src={liveSharing} alt='dollhouse' className='icon-button-card' />
                <Trans id='Start live sharing' />
              </div>
            )}
            {showOrderDollhouse && (
              <>
                {tour.dollhouseExist ? (
                  <div className='ordered-dollhouse-btn'>
                    <img src={dollHouse} alt='dollhouse' className='icon-button-card' />
                    <Trans id='Dollhouse Ordered' />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      const modalContent = {
                        id: 'order-dollhouse',
                        title: linguiTranslateToString('Order Dollhouse'),
                        tourName: tour.name ? tour.name : '',
                        tourId: tour.id ? tour.id : '',
                        backUrl: 'live-tours',
                        panoramaCount: tour.panoramaCount,
						apiNoPayment: apiNoPayment,
                      };
                      showModal(modalContent);
                      ReportCustomEvent(EnumCategory.Dollhouse, EnumEvent.OrderDollhouse);
                    }}
                    className='order-dollhouse-btn'
                  >
                    <img src={dollHouse} alt='dollhouse' className='icon-button-card' />
                    <Trans id='Order dollhouse' />
                  </div>
                )}
              </>
            )}
            {type !== 'archived' && type !== 'deleted' && (
              <div className={showOrderDollhouse ? 'go-to-studio-btn-container' : 'go-to-studio-big'}>
                <Link
                  className='default-link link-style-adjustment'
                  to={{
                    pathname: `/editing-viewer/`,
                    search: `?tourId=${tour.id}`,
                    state: {
                      tour: tour,
                      paginationPage: page,
                      searchWord: keyword,
                      tourIndex: index,
                      displayDashboard: true,
                      orderOptionName: orderOptionName,
                      orderOption: orderOption,
                      dashboardView: 'rows',
                    },
                  }}
                >
                  <button
                    id='connectGoToStudio'
                    className='go-to-studio-btn'
                    onClick={() => {
                      ReportCustomEvent(EnumCategory.TourMenu, EnumEvent.OpenEditor);
                    }}
                  >
                    <div className='go-to-studio-icon'></div>
                    <Trans id='Go to studio' />
                  </button>
                </Link>
              </div>
            )}
            {type === 'archived' ? (
              <div className='archive-btn-container'>
                <button
                  id='unarchive'
                  className='unarchive-btn'
                  onClick={() => {
                    if (archiveTours) {
                      ReportCustomEvent(EnumCategory.ArchivedTourMenu, EnumEvent.UnarchiveTour);
                      archiveTours(tour.id, false);
                    }
                  }}
                >
                  <div className='unarchive-icon'></div>
                  <Trans id='Unarchive' />
                </button>
                {deleteArchived && (
                  <button
                    id='delete'
                    className='delete-btn-card'
                    onClick={() => {
                      ReportCustomEvent(EnumCategory.ArchivedTourMenu, EnumEvent.DeleteTour);
                      deleteArchived(tour.id);
                    }}
                  >
                    <div className='delete-icon'></div>
                    <Trans id='Delete' />
                  </button>
                )}
              </div>
            ) : type === 'deleted' ? (
              <div className='btn-restore-container'>
                <button
                  id='restore'
                  className='restore-btn'
                  onClick={() => {
                    if (restoreTours) {
                      ReportCustomEvent(EnumCategory.TourMenu, EnumEvent.RestoreTour);
                      restoreTours(tour.id);
                    }
                  }}
                >
                  <div className='restore-icon'></div>
                  <Trans id='Restore' />
                </button>
              </div>
            ) : null}
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};

export default TourRows;
