import React, { useState, useEffect } from 'react';
import { Trans } from '@lingui/react';
import { ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../../google-analytics-track-list';
import { INewTour, TabStateEnum } from './create-tour';
import { handleError } from '../../../api-helper/api-error-handling';
import { useAuth } from '../../../auth-routes/auth-context';
import { getTourConfig, updateTourSettings } from '../../../api-helper/api-tours';
import { ITourSettings } from '../../../api-helper/interface/interfaces';
import { EnumNotificationType } from '../../utils/notifications/notification';
import { INewError } from '@vva/front/api-client/interfaces';
import { getConfig } from '../../../api-helper/api-account';
import { useConfig } from '../../utils/user-config-context/user-config-context';
import { ISidebar } from '../../editing-viewer/viewer-canvas';
import proTag from '../../../images/general-icons/pro-tag.svg';
import { linguiTranslateToString } from '../../utils/lingui-utils/utils-lingui';
import { createSubscriptionPortal } from '../../../api-helper/api-payment';

interface IProps {
  proAccount: boolean;
  setTabsState: (state: TabStateEnum) => void;
  toggleNotification: (type: EnumNotificationType, message?: string) => void;
  newTour?: INewTour;
  tourCreated: boolean;
}

const TourSettings: React.FC<IProps> = ({ proAccount, setTabsState, toggleNotification, newTour, tourCreated }) => {
  const [tourSettings, setTourSettings] = useState<ITourSettings>();
  const { handleLogoutState } = useAuth();
  const config = useConfig();
  const [aiFeatures, setAiFeatures] = useState<ISidebar>({
    autoEnhance: false,
    autoBlur: false,
    autoTripodRemoval: false,
    autoAlignment: false,
    multiRes: false,
  });
  const [currency, setCurrency] = useState(linguiTranslateToString('gbp'));

  useEffect(() => {
    if (config) {
      const currency = config.countryInfo;
      if (currency) {
        setCurrency(currency.currency.toLowerCase());
      }
    }
  }, [config]);

  useEffect(() => {
    if (config && config.modules) {
      let sidebarTemp = { ...aiFeatures };
      for (const menu of config.modules) {
        const menuKey = Object.keys(menu);
        const key = aiFeatures[menuKey[0]];
        // if there is a config for a left sidebar option add it
        if (key !== undefined) {
          sidebarTemp[menuKey[0]] = menu[menuKey[0]];
        }
        setAiFeatures(sidebarTemp);
      }
    }
  }, [config]);

  useEffect(() => {
    if (tourCreated) {
      getTourConfiguration();
    } else {
      // Default before tour is created
      getConfiguration();
    }
  }, []);

  const getConfiguration = async () => {
    try {
      const getUserConfig = await getConfig();
      if (getUserConfig) {
        let obj: ITourSettings = {
          autoBlur: false,
          tripodRemoved: false,
          autoAligned: false,
          autoEnhance: false,
          shareLocation: false,
        };
        for (const config of getUserConfig) {
          const value = config.userValue;
          if (Object.keys(config.src).length !== 0) {
            const key = config.src as keyof ITourSettings;
            obj[key] = value;
          }
        }
        const settings = {
          autoBlur: obj.autoBlur,
          tripodRemoved: obj.tripodRemoved,
          autoAligned: obj.autoAligned,
          autoEnhance: obj.autoEnhance,
          shareLocation: obj.shareLocation,
          multiResolution: obj.multiResolution,
        };
        setTourSettings(settings);
      }
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const getTourConfiguration = async () => {
    if (newTour?.id && newTour.id !== '') {
      try {
        const response = await getTourConfig(newTour?.id);
        const settings = {
          autoBlur: response.aiFeatures.autoBlur,
          tripodRemoved: response.aiFeatures.tripodRemoved,
          autoAligned: response.aiFeatures.autoAligned,
          autoEnhance: response.aiFeatures.autoEnhance,
          shareLocation: response.tourConfiguration.shareLocation,
          multiResolution: response.aiFeatures.multiResolution,
        };
        setTourSettings(settings);
      } catch (error) {
        const err = error as Error;
        handleError(err, handleLogoutState);
        toggleNotification(EnumNotificationType.Error);
      }
    }
  };

  const saveTourSettings = async () => {
    if (!newTour?.id) {
      toggleNotification(EnumNotificationType.Error, 'No tour created yet');
      return;
    }
    if (!tourSettings) {
      // Nothing to update
      setTabsState(TabStateEnum.UploadImages);
      return;
    }
    try {
      await updateTourSettings(newTour.id, tourSettings);
      setTabsState(TabStateEnum.UploadImages);
    } catch (error) {
      const err = error as INewError;
      if (err.getMessage().includes('Some panoramas are still processing. Please wait')) {
        toggleNotification(
          EnumNotificationType.Warning,
          'Just a few more seconds. The images are being reprocessed with the settings changes made',
        );
      } else {
        toggleNotification(EnumNotificationType.Error, err.getMessage() || 'Error changing tour settings');
      }
      handleError(err, handleLogoutState);
    }
  };

  const upgradeToProPlan = async () => {
    try {
      const createPaymentSession = await createSubscriptionPortal(currency);
      if (createPaymentSession?.redirectUrl) {
        // redirect to payment checkout
        window.location.href = createPaymentSession.redirectUrl;
      }
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      toggleNotification(EnumNotificationType.Error, linguiTranslateToString('There was an issue creating payment session'));
    }
  };

  return (
    <>
      <div className='create-tour-info-choose-settings'>
        <div className='create-tour-input-container' style={{ marginBottom: '70px' }}>
          <div className='create-tour-input'>
            <div className='check-tour-text' style={{ width: '85%' }}>
              <div className='create-tour-choose-settings'>
                <Trans id='Display location' render={({ translation }) => <div className='create-tour-labels'>{translation}</div>} />
                <div className='field-input-container' style={{ margin: '0' }}>
                  <label className='switch-settings'>
                    <input
                      id='shareLocation'
                      type='checkbox'
                      checked={tourSettings?.shareLocation || false}
                      onClick={() => {
                        if (tourSettings?.shareLocation) {
                          //Report Custom Category and Event
                          ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.DisableShareLocation);
                        } else {
                          //Report Custom Category and Event
                          ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.EnableShareLocation);
                        }
                      }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setTourSettings({ ...tourSettings, shareLocation: e.target.checked === true ? true : false })
                      }
                    />
                    <span id='slider-auto-start-tour' className='slider-settings round-settings'>
                      <span className={`slider-text-${tourSettings?.shareLocation ? 'on' : 'off'}`}>
                        {tourSettings?.shareLocation ? 'ON' : 'OFF'}
                      </span>
                    </span>
                  </label>
                </div>
              </div>
              <Trans
                id='Enable Google street view and full address'
                render={({ translation }) => <div className='choose-tour-settings-labels'>{translation}</div>}
              />
            </div>
          </div>
          <div className='create-tour-input'>
            <div className='check-tour-text' style={{ width: '85%' }}>
              <div className='create-tour-choose-settings'>
                <div className='new-feature-text'>
                  <Trans
                    id='Auto blur'
                    render={({ translation }) => (
                      <div className={proAccount ? 'create-tour-labels' : 'create-tour-labels-disabled'}>{translation}</div>
                    )}
                  />
                  <div>
                    <img style={{}} src={proTag} alt='pro' />
                  </div>
                </div>
                {aiFeatures.autoBlur ? (
                  <div className='field-input-container' style={{ margin: '0' }}>
                    <label className='switch-settings'>
                      <input
                        id='autoBlur'
                        type='checkbox'
                        checked={tourSettings?.autoBlur || false}
                        onClick={() => {
                          if (tourSettings?.autoBlur) {
                            //Report Custom Category and Event
                            ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.DisableBlur);
                          } else {
                            //Report Custom Category and Event
                            ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.EnableBlur);
                          }
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setTourSettings({ ...tourSettings, autoBlur: e.target.checked === true ? true : false })
                        }
                      />
                      <span id='slider-auto-start-tour' className='slider-settings round-settings'>
                        <span className={`slider-text-${tourSettings?.autoBlur ? 'on' : 'off'}`}>
                          {tourSettings?.autoBlur ? 'ON' : 'OFF'}
                        </span>
                      </span>
                    </label>
                  </div>
                ) : (
                  <label className='switch-settings'>
                    <input id='enableTripod' type='checkbox' checked={false} disabled={true} />
                    <span id='slider-auto-start-tour' className='slider-settings-disable round-settings'></span>
                  </label>
                )}
              </div>
              <Trans
                id='Automatically detect and blur faces in all your images'
                render={({ translation }) => <div className='choose-tour-settings-labels'>{translation}</div>}
              />
            </div>
          </div>
        </div>
        <div className='create-tour-input-container' style={{ marginBottom: '70px' }}>
          <div className='create-tour-input'>
            <div className='check-tour-text' style={{ width: '85%' }}>
              <div className='create-tour-choose-settings'>
                <div className='new-feature-text'>
                  <Trans
                    id='Auto-enhance all images'
                    render={({ translation }) => (
                      <div className={proAccount ? 'create-tour-labels' : 'create-tour-labels-disabled'}>{translation}</div>
                    )}
                  />
                  <div>
                    <img style={{}} src={proTag} alt='pro' />
                  </div>
                </div>
                {aiFeatures.autoEnhance ? (
                  <div className='field-input-container' style={{ margin: '0' }}>
                    <label className='switch-settings'>
                      <input
                        id='autoEnhance'
                        type='checkbox'
                        checked={tourSettings?.autoEnhance || false}
                        onClick={() => {
                          if (tourSettings?.autoEnhance) {
                            //Report Custom Category and Event
                            ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.DisableEnhance);
                          } else {
                            //Report Custom Category and Event
                            ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.EnableEnhance);
                          }
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setTourSettings({ ...tourSettings, autoEnhance: e.target.checked === true ? true : false })
                        }
                      />
                      <span id='slider-auto-start-tour' className='slider-settings round-settings'>
                        <span className={`slider-text-${tourSettings?.autoEnhance ? 'on' : 'off'}`}>
                          {tourSettings?.autoEnhance ? 'ON' : 'OFF'}
                        </span>
                      </span>
                    </label>
                  </div>
                ) : (
                  <label className='switch-settings'>
                    <input id='enableTripod' type='checkbox' checked={false} disabled={true} />
                    <span id='slider-auto-start-tour' className='slider-settings-disable round-settings'></span>
                  </label>
                )}
              </div>
              <Trans
                id='Automatically enhance all images on upload'
                render={({ translation }) => <div className='choose-tour-settings-labels'>{translation}</div>}
              />
            </div>
          </div>
          <div className='create-tour-input'>
            <div className='check-tour-text' style={{ width: '85%' }}>
              <div className='create-tour-choose-settings'>
                <div className='new-feature-text'>
                  <Trans
                    id='Auto-alignment'
                    render={({ translation }) => (
                      <div className={proAccount ? 'create-tour-labels' : 'create-tour-labels-disabled'}>{translation}</div>
                    )}
                  />
                  <div>
                    <img style={{}} src={proTag} alt='pro' />
                  </div>
                </div>
                {aiFeatures.autoAlignment ? (
                  <div className='field-input-container' style={{ margin: '0' }}>
                    <label className='switch-settings'>
                      <input
                        id='autoAligned'
                        type='checkbox'
                        checked={tourSettings?.autoAligned || false}
                        onClick={() => {
                          if (tourSettings?.autoAligned) {
                            //Report Custom Category and Event
                            ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.DisableAutoAligned);
                          } else {
                            //Report Custom Category and Event
                            ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.EnableAutoAligned);
                          }
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setTourSettings({ ...tourSettings, autoAligned: e.target.checked === true ? true : false })
                        }
                      />
                      <span id='slider-auto-start-tour' className='slider-settings round-settings'>
                        <span className={`slider-text-${tourSettings?.autoAligned ? 'on' : 'off'}`}>
                          {tourSettings?.autoAligned ? 'ON' : 'OFF'}
                        </span>
                      </span>
                    </label>
                  </div>
                ) : (
                  <label className='switch-settings'>
                    <input id='enableTripod' type='checkbox' checked={false} disabled={true} />
                    <span id='slider-auto-start-tour' className='slider-settings-disable round-settings'></span>
                  </label>
                )}
              </div>
              <Trans
                id='Automatically straightnen the images on upload'
                render={({ translation }) => <div className='choose-tour-settings-labels'>{translation}</div>}
              />
            </div>
          </div>
        </div>
        <div className='create-tour-input-container' style={{ marginBottom: '70px' }}>
          <div className='create-tour-input'>
            <div className='check-tour-text' style={{ width: '85%' }}>
              <div className='create-tour-choose-settings'>
                <div className='new-feature-text'>
                  <Trans
                    id='Auto tripod removal'
                    render={({ translation }) => (
                      <div className={proAccount ? 'create-tour-labels' : 'create-tour-labels-disabled'}>{translation}</div>
                    )}
                  />
                  <div>
                    <img style={{}} src={proTag} alt='pro' />
                  </div>
                </div>
                {aiFeatures.autoTripodRemoval ? (
                  <div className='field-input-container' style={{ margin: '0' }}>
                    <label className='switch-settings'>
                      <input
                        id='tripodRemoved'
                        type='checkbox'
                        checked={tourSettings?.tripodRemoved || false}
                        onClick={() => {
                          if (tourSettings?.tripodRemoved) {
                            //Report Custom Category and Event
                            ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.DisableTripodRemoved);
                          } else {
                            //Report Custom Category and Event
                            ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.EnableTripodRemoved);
                          }
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setTourSettings({ ...tourSettings, tripodRemoved: e.target.checked === true ? true : false })
                        }
                      />
                      <span id='slider-auto-start-tour' className='slider-settings round-settings'>
                        <span className={`slider-text-${tourSettings?.tripodRemoved ? 'on' : 'off'}`}>
                          {tourSettings?.tripodRemoved ? 'ON' : 'OFF'}
                        </span>
                      </span>
                    </label>
                  </div>
                ) : (
                  <label className='switch-settings'>
                    <input id='enableTripod' type='checkbox' checked={false} disabled={true} />
                    <span id='slider-auto-start-tour' className='slider-settings-disable round-settings'></span>
                  </label>
                )}
              </div>
              <Trans
                id='Automatically remove the tripod from the bottom of all your images'
                render={({ translation }) => <div className='choose-tour-settings-labels'>{translation}</div>}
              />
            </div>
          </div>
          <div className='create-tour-input'>
            {aiFeatures.multiRes && (
              <div className='check-tour-text' style={{ width: '85%' }}>
                <div className='create-tour-choose-settings'>
                  <div className='new-feature-text'>
                    <Trans
                      id='Multi-resolution'
                      render={({ translation }) => (
                        <div className={proAccount ? 'create-tour-labels' : 'create-tour-labels-disabled'}>{translation}</div>
                      )}
                    />
                    <div>
                      <img style={{}} src={proTag} alt='pro' />
                    </div>
                  </div>
                  <div className='field-input-container' style={{ margin: '0' }}>
                    {proAccount ? (
                      <label className='switch-settings'>
                        <input
                          id='multiResolution'
                          type='checkbox'
                          checked={tourSettings?.multiResolution || false}
                          onClick={() => {
                            if (tourSettings?.multiResolution) {
                              //Report Custom Category and Event
                              ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.DisableMultires);
                            } else {
                              //Report Custom Category and Event
                              ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.EnableMultires);
                            }
                          }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setTourSettings({ ...tourSettings, multiResolution: e.target.checked === true ? true : false })
                          }
                        />
                        <span id='slider-auto-start-tour' className='slider-settings round-settings'>
                          <span className={`slider-text-${tourSettings?.multiResolution ? 'on' : 'off'}`}>
                            {tourSettings?.multiResolution ? 'ON' : 'OFF'}
                          </span>
                        </span>
                      </label>
                    ) : (
                      <label className='switch-settings'>
                        <input id='enableTripod' type='checkbox' checked={false} disabled={true} />
                        <span id='slider-auto-start-tour' className='slider-settings-disable round-settings'></span>
                      </label>
                    )}
                  </div>
                </div>
                <Trans
                  id='Converts panorama image into tiles to display a higher resolution image'
                  render={({ translation }) => <div className='choose-tour-settings-labels'>{translation}</div>}
                />
              </div>
            )}
          </div>
        </div>
        <div className='container-upload-btn'>
          {!proAccount && (
            <button
              id='save-btn-your-branding'
              className='save-information-button button-white'
              onClick={() => {
                ReportCustomEvent(EnumCategory.MainMenu, EnumEvent.UpdateToProAccount);
                upgradeToProPlan();
              }}
            >
              <Trans id='Upgrade account to PRO to unlock this feature' />
            </button>
          )}
          <button
            data-testid='switch-state-button'
            id='switch-state-button'
            className={'create-tour-btn-size btn-new-design btn-new-active button-hover'}
            onClick={() => saveTourSettings()}
          >
            <Trans id='Upload 360 images' />
          </button>
        </div>
      </div>
    </>
  );
};

export default TourSettings;
