import React, { useEffect, useRef, useState } from 'react';
import { ReportCustomEvent } from '../../../../GoogleAnalyticsConfig';
import { Trans } from '@lingui/react';
import backArrow from '../../../../images/viewer-icons/left-circle-arrow.svg';
import forwardArrow from '../../../../images/viewer-icons/right-circle-arrow.svg';
import backArrowDisable from '../../../../images/viewer-icons/left-circle-arrow-disable.svg';
import forwardArrowDisable from '../../../../images/viewer-icons/right-circle-arrow-disable.svg';
import { IBranding } from '../../../main/settings/settings';
import { EnumCategory, EnumEvent } from '../../../../google-analytics-track-list';
import { IReadAllPanoramaLogos } from '../../../../api-helper/interface/interfaces';
import { deleteTripodLogo, getTripodCapLogos, uploadLogoTripod } from '../../../../api-helper/api-panoramas';
import { paginateModalSlider } from '../../../utils/pagination/utils';
import { getDefaultTripodTour, setDefaultTripodTour, updateTourGeneralConfig } from '../../../../api-helper/api-tours';
import { EnumNotificationType } from '../../../utils/notifications/notification';
import { handleError } from '../../../../api-helper/api-error-handling';
import { useAuth } from '../../../../auth-routes/auth-context';
import { ILeftSidebar } from '../../viewer-canvas-utils/viewer-canvas-helper';
import { ISidebar } from '../../viewer-canvas';
import { linguiTranslateToString } from '../../../utils/lingui-utils/utils-lingui';
import { useConfig } from '../../../utils/user-config-context/user-config-context';
import { createSubscriptionPortal } from '../../../../api-helper/api-payment';

interface IProps {
  sidebar: ISidebar;
  tour: any;
  savedChanges: boolean;
  setSavedChanges: (state: boolean) => void;
  setLoaded: (state: boolean) => void;
  branding: IBranding;
  setBranding: (state: IBranding) => void;
  toggleNotification: (type: EnumNotificationType, message?: string) => void;
  leftSidebar: ILeftSidebar;
}

interface IFiles {
  data: Buffer | string;
  defaultLogo: boolean;
  id: string;
}

const Branding: React.FC<IProps> = ({
  tour,
  savedChanges,
  setSavedChanges,
  setLoaded,
  branding,
  setBranding,
  toggleNotification,
  leftSidebar,
  sidebar,
}) => {
  const { handleLogoutState } = useAuth();
  const [pageArray, setPageArray] = useState<IReadAllPanoramaLogos[][]>([]);
  const [page, setPage] = useState<number>(0);
  const [defaultLogo, setDefaultLogo] = useState<string>();
  const [files, setFiles] = useState<IFiles[]>([]);
  const [deletedLogos, setDeletedLogos] = useState<string[]>([]);
  const limit = 3;
  const ref = useRef<any>();
  const [proAccount, setProAccount] = useState<boolean>(false);
  const config = useConfig();
  const [currency, setCurrency] = useState(linguiTranslateToString('gbp'));

  useEffect(() => {
    if (config) {
      const currency = config.countryInfo;
      if (currency) {
        setCurrency(currency.currency.toLowerCase());
      }
    }
  }, [config]);

  useEffect(() => {
    if (leftSidebar.settings.status) {
      getTripodLogos();
    }
  }, [leftSidebar]);

  useEffect(() => {
    if (sidebar.tripodBranding) {
      setProAccount(sidebar.tripodBranding);
    }
  }, [sidebar]);

  const getTripodLogos = async () => {
    try {
      const tripodsLogos = await getTripodCapLogos();
      const defaultTourLogo = await getDefaultTripodTour(tour.id);
      let tripodArray: IFiles[] = [];
      if (tripodsLogos) {
        tripodArray = tripodsLogos.map((tripodLogo: IReadAllPanoramaLogos) => {
          if (defaultTourLogo) {
            setDefaultLogo(defaultTourLogo.id);
          }
          return {
            data: tripodLogo.cubeUrl,
            defaultLogo: tripodLogo.id === defaultTourLogo.id,
            id: tripodLogo.id,
          };
        });
      }
      if (tripodArray.length > 0) {
        const pagePanoramas = paginateModalSlider(tripodArray, limit);
        setPageArray(pagePanoramas);
        setFiles(tripodArray);
      }
      setLoaded(true);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      setLoaded(true);
    }
  };

  const onFileDrop = async (e: any) => {
    if (e.target.files.length > 0) {
      setLoaded(false);
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i] || e.dataTransfer.files[i];
        try {
          await uploadLogoTripod(file);
        } catch (error) {
          const err = error as Error;
          handleError(err, handleLogoutState);
          setLoaded(true);
        }
      }
      if (ref && ref.current) {
        ref.current.value = '';
      }
      getTripodLogos();
    }
  };

  const deleteSelectedLogo = (imageIndex: number) => {
    const logoId = files[page * 3 + imageIndex].id ? files[page * 3 + imageIndex].id : null;
    const index = files.findIndex((a: IFiles) => a.id === logoId);
    if (logoId) {
      setDeletedLogos([...deletedLogos, logoId]);
    }
    let arrayOfImages = [...files];
    arrayOfImages.splice(index, 1);
    const pagePanoramas = paginateModalSlider(arrayOfImages, limit);
    if (pagePanoramas[page]) {
      setPageArray(pagePanoramas);
    } else {
      setPage(pagePanoramas.length - 1);
      setPageArray(pagePanoramas);
    }
    setFiles(arrayOfImages);
  };

  const selectDefaultLogo = async (selectedIndex: number) => {
    setDefaultLogo(files[page * 3 + selectedIndex].id);
    let arrayOfFiles = [...files].map((file, index) => {
      const convertIndex = page * limit + selectedIndex;
      if (index === convertIndex) {
        file.defaultLogo = true;
      } else {
        file.defaultLogo = false;
      }
      return file;
    });
    const pagePanoramas = paginateModalSlider(arrayOfFiles, limit);
    setPageArray(pagePanoramas);
    setFiles(arrayOfFiles);
  };

  const saveChanges = async () => {
    ReportCustomEvent(EnumCategory.branding, EnumEvent.SaveChanges);
    setSavedChanges(!savedChanges);
    try {
      await updateTourGeneralConfig(branding, tour.id);

      if (defaultLogo && defaultLogo !== '') {
        // tour option
        await setDefaultTripodTour(tour.id, defaultLogo);
      }

      // Delete any deleted logos
      if (deletedLogos.length > 0) {
        for (const logo of deletedLogos) {
          await deleteTripodLogo(logo);
        }
      }

      setLoaded(true);
      toggleNotification(EnumNotificationType.Success, 'Tour has been updated');
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      setLoaded(true);
      toggleNotification(EnumNotificationType.Error);
    }
  };

  const upgradeToProPlan = async () => {
    try {
      const createPaymentSession = await createSubscriptionPortal(currency);
      if (createPaymentSession?.redirectUrl) {
        // redirect to payment checkout
        window.location.href = createPaymentSession.redirectUrl;
      }
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      toggleNotification(EnumNotificationType.Error, linguiTranslateToString('There was an issue creating payment session'));
    }
  };

  return (
    <div className='settings-modal-container-profile-branding'>
      <div className='update-tour-container-branding'>
        <div className='settings-branding-logo'>
          <div style={{ cursor: 'default' }}>
            <div className='settings-choose-logo-text'>
              <Trans id='Choose the logo for ' />
              <b>{(tour && ' ' + tour.name) || ''}.</b>
            </div>
            <div className='logo-tripod-requirements'>
              <Trans id='Format must be a JPEG or PNG.' />
            </div>
          </div>
          {proAccount ? (
            <div className='field-input-container'>
              <label className='switch-settings'>
                <input
                  id='forceBrand'
                  type='checkbox'
                  checked={branding?.forceBrand || false}
                  disabled={!proAccount}
                  onClick={() => {
                    if (branding?.forceBrand) {
                      //Report Custom Category and Event
                      ReportCustomEvent(EnumCategory.MainMenu, EnumEvent.DisableTripodRemoved);
                    } else {
                      //Report Custom Category and Event
                      ReportCustomEvent(EnumCategory.MainMenu, EnumEvent.EnableTripodRemoved);
                    }
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setBranding({ ...branding, forceBrand: e.target.checked === true ? true : false })
                  }
                />
                <span id='slider-branding' className='slider-settings round-settings'>
                  <span className={`slider-text-${branding?.forceBrand ? 'on' : 'off'}`}>{branding?.forceBrand ? 'ON' : 'OFF'}</span>
                </span>
              </label>
            </div>
          ) : (
            <div className='field-input-container'>
              <label className='switch-settings'>
                <input id='enableTripod' type='checkbox' disabled={!proAccount} />
                <span id='slider-auto-start-tour' className='slider-settings-disable round-settings'></span>
              </label>
            </div>
          )}
        </div>
        <div className='container-inputs-branding'>
          <div className='input-box'>
            <Trans id='Company name *' render={({ translation }) => <div className='branding-input-labels'>{translation}</div>} />
            <input
              className={`general-field-input-text-branding ${branding.brandName !== '' ? 'valid-input' : ''} ${
                !proAccount && 'general-field-input-text-disabled'
              }`}
              type='text'
              id='brandName'
              name='brandName'
              value={branding.brandName || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBranding({ ...branding, brandName: e.target.value })}
              disabled={!proAccount}
              style={{ color: !proAccount ? '#F2F3F5' : '' }}
            />
          </div>
          <div className='input-box'>
            <Trans id='Address *' render={({ translation }) => <div className='branding-input-labels'>{translation}</div>} />
            <input
              className={`general-field-input-text-branding ${branding.brandAddress !== '' ? 'valid-input' : ''} ${
                !proAccount && 'general-field-input-text-disabled'
              }`}
              type='text'
              id='brandAddress'
              name='brandAddress'
              value={branding.brandAddress || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBranding({ ...branding, brandAddress: e.target.value })}
              disabled={!proAccount}
              style={{ color: !proAccount ? '#F2F3F5' : '' }}
            />
          </div>
          <div className='input-box'>
            <Trans id='Email *' render={({ translation }) => <div className='branding-input-labels'>{translation}</div>} />
            <input
              className={`general-field-input-text-branding ${branding.brandEmail !== '' ? 'valid-input' : ''} ${
                !proAccount && 'general-field-input-text-disabled'
              }`}
              style={{ color: !proAccount ? '#F2F3F5' : '' }}
              type='text'
              id='brandEmail'
              name='brandEmail'
              value={branding.brandEmail || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBranding({ ...branding, brandEmail: e.target.value })}
              disabled={!proAccount}
            />
          </div>
        </div>
        <div className='drag-drop-input-container'>
          <div className='settings-display-tripods-branding'>
            <button className='btn-remove-default' onClick={() => setPage(page === 0 ? page : page - 1)}>
              <img src={!proAccount ? backArrowDisable : page <= 0 ? backArrowDisable : backArrow} alt='Back slider' />
            </button>
            <div
              className='settings-drag-drop-container'
              style={{ border: proAccount ? '' : '1px solid #C7CCDA', backgroundColor: !proAccount ? '#F2F3F5' : '' }}
            >
              <div className='settings-drag-drop-text-container'>
                <Trans
                  id='Drag logo here '
                  render={({ translation }) => (
                    <p style={{ color: proAccount ? '' : '#C7CCDA' }} className='drag-drop-text'>
                      {translation}
                    </p>
                  )}
                />
                <Trans
                  id='or'
                  render={({ translation }) => (
                    <p style={{ color: proAccount ? '' : '#C7CCDA' }} className='line-between-text drag-drop-text'>
                      {translation}
                    </p>
                  )}
                />
                <Trans
                  id='Choose upload'
                  render={({ translation }) => (
                    <p
                      style={{ color: proAccount ? '' : '#C7CCDA', border: proAccount ? '' : '1px solid #C7CCDA' }}
                      className='choose-file-button'
                    >
                      {translation}
                    </p>
                  )}
                />
              </div>
              <input
                id='input-file'
                type='file'
                name='logo-drop-zone'
                className='branding-input-settings'
                multiple
                onDragOver={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                ref={ref}
                disabled={!proAccount}
                onDrop={(e: any) => onFileDrop(e)}
                onChange={(e: any) => onFileDrop(e)}
              />
            </div>
            {proAccount &&
              pageArray &&
              pageArray.length > 0 &&
              pageArray[page] &&
              pageArray[page].map((file: any, index: number) => (
                <div key={index} className='dd-image-container'>
                  <div className='dd-cross-container' onClick={() => deleteSelectedLogo(index)}></div>
                  <img src={file.data} alt={file.name} className='dd-image' />
                  {file.defaultLogo === true ? (
                    <Trans id='Tour Logo' render={({ translation }) => <p className='dd-text underlined default-logo'>{translation}</p>} />
                  ) : (
                    <Trans
                      id='Set as tour logo'
                      render={({ translation }) => (
                        <p className='dd-text underlined not-default-logo' onClick={() => selectDefaultLogo(index)}>
                          {translation}
                        </p>
                      )}
                    />
                  )}
                </div>
              ))}

            <button className='btn-remove-default' onClick={() => setPage(page + 1 !== pageArray.length ? page + 1 : page)}>
              <img
                src={!proAccount ? forwardArrowDisable : pageArray && page === pageArray.length - 1 ? forwardArrowDisable : forwardArrow}
                alt='Forward slider'
              />
            </button>
          </div>
        </div>
        <div className='display-flex flex-center'>
          <div className='list-panoramas-dots-navigation-branding'>
            {proAccount &&
              pageArray &&
              pageArray.map((item: IReadAllPanoramaLogos[], index: number) => (
                <span
                  key={index}
                  onClick={() => setPage(index)}
                  className={`dot-navigation ${page === index ? 'dot-navigation-active' : ''}`}
                ></span>
              ))}
          </div>
        </div>
        <div className='modal-btn-container-branding'>
          {proAccount ? (
            <>
              {!savedChanges ? (
                <div
                  onClick={() => {
                    if (branding.brandName !== '' && branding?.brandAddress !== '' && branding?.brandEmail !== '') {
                      saveChanges();
                    }
                  }}
                  className={`editor-modal-btn-branding  ${
                    branding.brandName !== '' && branding?.brandAddress !== '' && branding?.brandEmail !== ''
                      ? 'editor-modal-btn-active-branding button-hover'
                      : 'button-inactive'
                  }`}
                >
                  <Trans id='Save changes' />
                </div>
              ) : (
                <div className='btn-confirm'>
                  <svg width='22' height='18' viewBox='0 0 22 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <g filter='url(#filter0_d_9829_12446)'>
                      <path
                        d='M8.59467 14.6036L3.71966 9.51892C3.42678 9.21345 3.42678 8.71815 3.71966 8.41265L4.7803 7.30637C5.07318 7.00087 5.54808 7.00087 5.84096 7.30637L9.125 10.7316L16.159 3.39512C16.4519 3.08965 16.9268 3.08965 17.2197 3.39512L18.2803 4.5014C18.5732 4.80687 18.5732 5.30216 18.2803 5.60767L9.65533 14.6036C9.36242 14.9091 8.88755 14.9091 8.59467 14.6036Z'
                        fill='white'
                      />
                    </g>
                    <defs>
                      <filter
                        id='filter0_d_9829_12446'
                        x='0.5'
                        y='0.166016'
                        width='21'
                        height='17.666'
                        filterUnits='userSpaceOnUse'
                        colorInterpolationFilters='sRGB'
                      >
                        <feFlood floodOpacity='0' result='BackgroundImageFix' />
                        <feColorMatrix
                          in='SourceAlpha'
                          type='matrix'
                          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                          result='hardAlpha'
                        />
                        <feOffset />
                        <feGaussianBlur stdDeviation='1.5' />
                        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0' />
                        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_9829_12446' />
                        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_9829_12446' result='shape' />
                      </filter>
                    </defs>
                  </svg>
                  <Trans id='Saved' />
                </div>
              )}
            </>
          ) : (
            <div className='save-information-button-container'>
              <button
                id='save-btn-your-branding'
                className='save-information-button button-active'
                onClick={() => {
                  ReportCustomEvent(EnumCategory.TourMenu, EnumEvent.UpdateToProAccount);
                  upgradeToProPlan();
                }}
              >
                <Trans id='Upgrade account to PRO to unlock this feature' />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Branding;
